
@mixin add-background-color-classes() {
  @each $name, $color in $foundation-palette {
    .bg-#{$name} {
      background-color: $color;
    }
  }
}

@mixin add-text-color-classes() {
  @each $name, $color in $foundation-palette {
    .text-#{$name},
    .text-#{$name} * {
      color: $color;
      &:hover,
      &:focus,
      &:active {
        color: $color;
      }       
    }
  }
}

@mixin add-button-color-classes() {
  @each $name, $color in $foundation-palette {
    .button.#{$name},
    .menu-button.#{$name} a {
        color: #fff;
        background-color: $color;
        border: $button-hollow-border-width $button-fill $color;
      &:hover,
      &:focus,
      &:active {
        color: $color;
        background-color: #fff;
        border: $button-hollow-border-width $button-fill $color;
      }     
       
    }
  }
}