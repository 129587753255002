.button,
.menu-button a,
.wpcf7-form-control.wpcf7-submit,
.post-password-form [type=submit] {
  display: inline-block;
  vertical-align: middle;
  margin: $button-margin;    
  padding: $button-padding;
  transition: $button-transition;    
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  line-height: $button-line-height;
  letter-spacing: $button-letter-spacing;
  text-transform: $button-text-transform;
  text-decoration: none;
  text-align: center;
  color: $button-color; 
  background-color: $button-background;   
  border: $button-hollow-border-width $button-fill $button-border-color;
  border-radius: $button-radius;
  cursor: pointer;
  -webkit-appearance: none;
  white-space: normal;  
  max-width: 100%;   

  &.min-width {
    min-width: $button-min-width;
  }
  
  &.small {
    padding: rem-calc(5px) rem-calc(20px);
  }

  &.rounded {
    border-radius: 30px;
  }

  &:hover, &:focus {
    color: $button-color-hover;
    border: $button-hollow-border-width $button-fill $button-border-color-hover;
    background-color: $button-background-hover;
    outline: none !important;      
  }    

  &:disabled,
  &:disabled:hover {
    color: $medium-gray;
    border: $button-hollow-border-width $button-fill $medium-gray;
    background-color: $white;
    outline: none !important; 
    cursor: not-allowed;     
  }

  @include breakpoint(small only) {
    min-width: 100%;
    word-break: break-word;
    padding: $button-padding-small;
  }       
}

@include add-button-color-classes;

.wpcf7-form-control.wpcf7-submit {
  border-radius: 30px;
}

.button.inverted {
  color: $button-color-hover;
  background-color: transparent;
  border: $button-hollow-border-width $button-fill $button-border-color-hover;

  .badge {
    color: $button-color;
    background: $button-background;    
  }      

  &:hover, &:focus {
    color: $button-color; 
    background-color: $button-background;   
    border: $button-hollow-border-width $button-fill $button-border-color;

    .badge {
      color: $button-color-hover;
      background: $button-background-hover;    
    }    
  }   
}

.button.white {
  color: $white;
  background-color: transparent;
  border: $button-hollow-border-width $button-fill $white;

  &:hover, &:focus {
    color: $black;
    background-color: $white;
    border: $button-hollow-border-width $button-fill $white;
  }   
} 

.button.black {
  color: $black;
  background-color: $white;
  border: $button-hollow-border-width $button-fill $black;

  &:hover, &:focus {
    color: $white;
    background-color: $black;
    border: $button-hollow-border-width $button-fill $black;
  }   
}   

.button.medium-gray {
  color: $black;
  background-color: $medium-gray;
  border: $button-hollow-border-width $button-fill $medium-gray;

  &:hover, &:focus {
    color: $white;
    background-color: $black;
    border: $button-hollow-border-width $button-fill $medium-gray;
  }   
}

.wpcf7-form-control.wpcf7-submit,
.button.red {
  color: $black;
  background-color: $olive;
  border: $button-hollow-border-width $button-fill $olive;

  &:hover, &:focus, &.inverted {
    color: $black;
    background-color: $white;
    border: $button-hollow-border-width $button-fill $olive;
  }   
}

.button.blue {
  color: $white;
  background-color: $blue;
  border: $button-hollow-border-width $button-fill $blue;

  &:hover, &:focus {
    color: $blue;
    background-color: $white;
    border: $button-hollow-border-width $button-fill $blue;
  }   
}

.button-wrap {
  position: relative;
  display: table;
  margin-top: 40px; 
  margin-left: auto;
  margin-right: auto;  

  .text-left &,
  &.text-left {
    margin-left: 0;
  }

  .button:not(:only-child) {
    display: table;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 100%; 

    .text-left &,
    &.text-left {
      margin-left: 0;
    }    
  }  
  .button:last-child {
    margin-bottom: 0;    
  }

  @include breakpoint(small only) {
    display: block;
    margin-top: 40px;
  }  
}

.more-button-wrap {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  
  @include breakpoint(small only) {
    @include xy-cell();
    margin-top: 0;
    margin-bottom: 10px;
  }

  .button {
    margin-bottom: 0;    
  }  
} 

.back-button-wrap {
  position: absolute;
  top: calc(#{$header-height} + 20px);
  width: 100%;
  text-align: left;
  z-index: 2;

  @include section-padding-left;

  .back-button {
    font-size: 16px;
    text-decoration: none;
    margin-left: -4px;

    .back-button-text {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
    }

    &:hover {
       color: $white;
    }  

    @include breakpoint(small only) {
      margin-left: -6px;
    }           
  }

  @include breakpoint(large down) {
    top: 20px;   
  }  
}