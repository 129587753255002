// extend Heading styles from typography/_base.scss
@each $size, $headers in $header-styles {
  @include breakpoint($size) {
    @each $header, $header-defs in $headers {
      $font-size-temp: 1rem;
      $font-size-offset-temp: 1rem;
      $line-height-offset-temp: 1;
      $margin-offset-temp: 0;
      $margin-top-temp: 0;
      $margin-bottom-temp: 0;

      #{$header}, .like-#{$header} {

          @if map-has-key($header-defs, font-size) {
            $font-size-temp: rem-calc(map-get($header-defs, font-size));
            font-size: $font-size-temp;
          } @else if map-has-key($header-defs, fs) {
            $font-size-temp: rem-calc(map-get($header-defs, fs));
            font-size: $font-size-temp;
          } @else if $size == $-zf-zero-breakpoint {
            font-size: $font-size-temp;
          }
          @if map-has-key($header-defs, line-height) {
            line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
          } @else if map-has-key($header-defs, lh) {
            line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
          } @else if $size == $-zf-zero-breakpoint {
            line-height: unitless-calc($header-lineheight, $font-size-temp);
          }

          @if map-has-key($header-defs, margin-top) {
            margin-top: rem-calc(map-get($header-defs, margin-top));
          } @else if map-has-key($header-defs, mt) {
            margin-top: rem-calc(map-get($header-defs, mt));
          } @else if $size == $-zf-zero-breakpoint {
            margin-top: 0;
          }
          @if map-has-key($header-defs, margin-bottom) {
            margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
          } @else if map-has-key($header-defs, mb) {
            margin-bottom: rem-calc(map-get($header-defs, mb));
          } @else if $size == $-zf-zero-breakpoint {
            margin-bottom: rem-calc($header-margin-bottom);
          }
        
        @if map-has-key($header-defs, margin-offset) {
          // calculate top and bottom margin offsets from line-heights
          @if map-has-key($header-defs, font-size) {
            $font-size-offset-temp: map-get($header-defs, font-size);
          } @else if map-has-key($header-defs, fs) {
            $font-size-offset-temp: map-get($header-defs, fs);
          } @else if $size == $-zf-zero-breakpoint {
            $font-size-offset-temp: $font-size-temp;
          }
          @if map-has-key($header-defs, line-height) {
            $line-height-offset-temp: map-get($header-defs, line-height);
          } @else if map-has-key($header-defs, lh) {
            $line-height-offset-temp: map-get($header-defs, lh);
          } @else if $size == $-zf-zero-breakpoint {
            $line-height-offset-temp: $header-lineheight;
          }
  
          @if map-has-key($header-defs, margin-top) {
            $margin-top-temp: map-get($header-defs, margin-top);
          } @else if map-has-key($header-defs, mt) {
            $margin-top-temp: map-get($header-defs, mt);
          } @else if $size == $-zf-zero-breakpoint {
            $margin-top-temp: 0;
          }
          @if map-has-key($header-defs, margin-bottom) {
            $margin-bottom-temp: map-get($header-defs, margin-bottom);
          } @else if map-has-key($header-defs, mb) {
            $margin-bottom-temp: map-get($header-defs, mb);
          } @else if $size == $-zf-zero-breakpoint {
            $margin-bottom-temp: $header-margin-bottom;
          }
            
            $margin-offset-temp: ($line-height-offset-temp - $font-size-offset-temp) / 2;
            margin-top: rem-calc($margin-top-temp - $margin-offset-temp);
            margin-bottom: rem-calc($margin-bottom-temp - $margin-offset-temp);

        }

        // add font-weight support for Heading styles 
          @if map-has-key($header-defs, font-weight) {
            font-weight: map-get($header-defs, font-weight);
          } @else if map-has-key($header-defs, fw) {
            font-weight: map-get($header-defs, fw);
          } @else if $size == $-zf-zero-breakpoint {
            font-weight: $header-font-weight;
          }       

      }
    }
  }
}

// helper functions to get header styles for other tags
@function header-font-size($breakpoint, $size) {
  @return rem-calc((map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size")) * 1px);
}
@function header-font-weight($breakpoint, $size) {
  @return (map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-weight"));
}
@function header-line-height($breakpoint, $size) {
  @return rem-calc((map-get(map-get(map-get($header-styles, $breakpoint), $size), "line-height")) * 1px);
}
@function header-margin-offset($breakpoint, $size) {
  @return (( map-get(map-get(map-get($header-styles, $breakpoint), $size), "line-height") - map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") ) / 2);
}
@function header-margin-top($breakpoint, $size) {
  @return rem-calc((( map-get(map-get(map-get($header-styles, $breakpoint), $size), "margin-top")) - header-margin-offset($breakpoint, $size)));
}
@function header-margin-bottom($breakpoint, $size) {
  @return rem-calc((( map-get(map-get(map-get($header-styles, $breakpoint), $size), "margin-bottom")) - header-margin-offset($breakpoint, $size)));
}

body {
  letter-spacing: $body-letter-spacing;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

h1 {
  text-transform: uppercase;
}
.small-h1 {
  font-size: header-font-size(medium, h2);
  line-height: header-line-height(medium, h2);
  font-weight: header-font-weight(medium, h2);
  margin-top: rem-calc(map-get(map-get(map-get($header-styles, medium), h2), "margin-top") - header-margin-offset(medium, h2));
  margin-bottom: rem-calc(map-get(map-get(map-get($header-styles, medium), h2), "margin-bottom") - header-margin-offset(medium, h2)); 

  @include breakpoint(small only) {
    font-size: header-font-size(small, h2);
    line-height: header-line-height(small, h2);
    font-weight: header-font-weight(small, h2);
    margin-top: rem-calc(map-get(map-get(map-get($header-styles, small), h2), "margin-top") - header-margin-offset(small, h2));
    margin-bottom: rem-calc(map-get(map-get(map-get($header-styles, small), h2), "margin-bottom") - header-margin-offset(small, h2)); 
  }    
}

.big-h2, 
em h2, 
h2 em,
strong h2, 
h2 strong {
  font-style: normal;
  font-size: header-font-size(medium, h1);
  line-height: header-line-height(medium, h1);
  font-weight: header-font-weight(medium, h1);
  margin-top: rem-calc(map-get(map-get(map-get($header-styles, medium), h1), "margin-top") - header-margin-offset(medium, h1));
  margin-bottom: rem-calc(map-get(map-get(map-get($header-styles, medium), h1), "margin-bottom") - header-margin-offset(medium, h1)); 
  text-transform: uppercase;

  @include breakpoint(small only) {
    font-size: header-font-size(small, h1);
    line-height: header-line-height(small, h1);
    font-weight: header-font-weight(small, h1);
    margin-top: rem-calc(map-get(map-get(map-get($header-styles, small), h1), "margin-top") - header-margin-offset(small, h1));
    margin-bottom: rem-calc(map-get(map-get(map-get($header-styles, small), h1), "margin-bottom") - header-margin-offset(small, h1)); 
  }    
}

p, ul, ol, dl, table, blockquote {
  .font-size-large &,
  &.font-size-large {
    font-size: rem-calc(24);
    //color: $dark-gray;

    .bg-black & {
      color: $white;
    }     
  } 
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 0.1em;

  &:only-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}

h1, h2, h3 {
  //text-transform: uppercase;
  text-transform: none;
}

p, ul, ol, dl, table, blockquote {
  &:last-child {
    margin-bottom: 0;    
  }
}

p, ul, ol, dl, table, blockquote {
  + h2 {
    margin-top: rem-calc($section-vertical-padding - header-margin-offset(medium, h2)); 
  
    @include breakpoint(small only) {
      margin-top: rem-calc($section-vertical-padding-small - header-margin-offset(small, h2)); 
    }
  }
}

table {
  display: block;
  max-width:100%;
  overflow-x: auto;
  //white-space: nowrap; 

  thead {
    td, 
    th {
      border-right: $table-border;
      vertical-align: baseline;
    }
  }
  tbody {
    td, 
    th {
      border-right: $table-border;
      vertical-align: baseline;
    }
  }    
}

ul {
  list-style-type: disc;
}

ul, ol {
  list-style-position: outside;
  margin-left: 1em;

  li:not(.menu-item) {
    margin-bottom: 10px;
  }
}

b, strong {
  letter-spacing: 0.1em;
  text-transform: none;
  font-weight: 600;
}

a {
  &:hover, &:focus {
    outline: none !important;      
  }  
}

span.lowercase {
  text-transform: lowercase !important;
}

