//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Flexbox Utilities
//  20. Forms
//  21. Label
//  22. Media Object
//  23. Menu
//  24. Meter
//  25. Off-canvas
//  26. Orbit
//  26b. Slick
//  27. Pagination
//  28. Progress Bar
//  29. Prototype Arrow
//  30. Prototype Border-Box
//  31. Prototype Border-None
//  32. Prototype Bordered
//  33. Prototype Display
//  34. Prototype Font-Styling
//  35. Prototype List-Style-Type
//  36. Prototype Overflow
//  37. Prototype Position
//  38. Prototype Rounded
//  39. Prototype Separator
//  40. Prototype Shadow
//  41. Prototype Sizing
//  42. Prototype Spacing
//  43. Prototype Text-Decoration
//  44. Prototype Text-Transformation
//  45. Prototype Text-Utilities
//  46. Responsive Embed
//  47. Reveal
//  48. Slider
//  49. Switch
//  50. Table
//  51. Tabs
//  52. Thumbnail
//  53. Title Bar
//  54. Tooltip
//  55. Xy Grid
//  56. Toggle Menu


// 1. Global
// ---------

$main-max-width: 2000px;
$logo-width: rem-calc(90);
$logo-width-scrolled: rem-calc(90);
$logo-width-small: rem-calc(80);
$section-max-width: 2000px;
$default-wrap-max-width: 770px;
$wide-wrap-max-width: 940px;
$narrow-wrap-max-width: 670px;
$headline-wrap-max-width: 1100px;
$section-vertical-padding: 70px;
$section-vertical-padding-small: 45px;
$section-inner-padding: 60px;
$section-inner-padding-medium: 30px;
$section-inner-padding-small: 15px;
$section-inner-padding-tiny: 10px;

$foundation-palette: (
  primary: #000,
  secondary: #e3000f,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
  light-gray: #f5f5f5,
  medium-gray: #ccc,
  dark-gray: #252525, 
  black: #000,
  white: #fff,
  red: #e3000f,
  blue: #338afb,
  olive: #90926a
);
$light-gray: get-color(light-gray);
$medium-gray: get-color(medium-gray);
$dark-gray: get-color(dark-gray);
$black: get-color(black);
$white: get-color(white);
$red: get-color(red);
$blue: get-color(blue);
$olive: get-color(olive);

$pergament: '../images/pergament.jpg';

@include add-foundation-colors;
@include add-background-color-classes;
@include add-text-color-classes;

$global-width: rem-calc(2000);
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-radius: 0;
$global-menu-vertical-padding: rem-calc(31);
$global-menu-horizontal-padding: 1rem;
$global-menu-padding: $global-menu-vertical-padding $global-menu-horizontal-padding;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$global-font-size: 100%;
$global-lineheight: 1.5;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-border-color: $light-gray;
$print-hrefs: true;

$body-background: $medium-gray;
$body-font-color: #6b6b6a;
$body-font-family: 'Special Elite','Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$body-letter-spacing: 0.02rem;
$body-antialiased: true;

$header-border-thickness: 1px;
$header-border-thickness-small: 1px;
$header-border-color: #ececec;
$header-background-color: $white;
$header-background-color-small: $white;
$header-height: rem-calc(110 + $header-border-thickness);
$header-height-scrolled: rem-calc(110 + $header-border-thickness);
$header-height-small: rem-calc(50 + $header-border-thickness-small);
$header-position-attribute: relative;
$header-position-attribute-small: fixed;

$topbar-height: 100%;
$topbar-height-small: 100%;
$topbar-padding: 0;
$topbar-background: $white;
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 0.5rem 1rem 0.5rem 0;
$topbar-input-width: 100%;
$topbar-unstack-breakpoint: medium;
$topbar-font-family: $body-font-family;
$topbar-font-weight: 600;
$topbar-font-size: 1.1rem;
$topbar-line-height: rem-calc(17px);
$topbar-letter-spacing: inherit;
$topbar-text-transform: uppercase;
$topbar-logo-spacing: 0;
$topbar-anchor-color: $black;
$topbar-anchor-hover-color: $medium-gray;
$topbar-anchor-hover-background-color: transparent;
$topbar-anchor-line-color: $secondary-color;
$topbar-anchor-line-thickness: 5px;
$topbar-anchor-line-position: bottom;

$footer-font-family: $body-font-family;
$footer-font-weight: 600;
$footer-font-size: rem-calc(16px);
$footer-line-height: rem-calc(16px);
$footer-letter-spacing: 0.200em;
$footer-text-transform: uppercase;
$footer-font-color: #aaaaa9; 
$footer-anchor-color: #5f5e5e;
$footer-anchor-hover-color: $black;
$footer-background: $white;

$divider-line-height: rem-calc(1);
$divider-line-color: $global-border-color;

$divider-block-height: rem-calc(14px);
$divider-block-color: $light-gray;

$spinkit-spinner-margin: 0 auto;
$spinkit-size: 50px;
$spinkit-spinner-color: $black;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 4px,
  medium: 30px,
);
$grid-column-gutter-bottom-margins: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: 'Special Elite', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$header-font-weight: 600;
$header-font-style: normal;
$header-color: $black;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-styles: (
  'small': (
    'h1': ('font-size': 36, 'line-height': 44, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': 40, 'margin-offset': true), 
    'h2': ('font-size': 32, 'line-height': 40, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': 40, 'margin-offset': true),
    'h3': ('font-size': 22, 'line-height': 24, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': 28),
    'h4': ('font-size': 18, 'line-height': $header-lineheight, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h5': ('font-size': 17, 'line-height': $header-lineheight, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h6': ('font-size': 16, 'line-height': $header-lineheight, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom)
  ),
  'medium': (
    'h1': ('font-size': 72, 'line-height': 82, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': 35, 'margin-offset': true),
    'h2': ('font-size': 42, 'line-height': 50, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': 38, 'margin-offset': true),
    'h3': ('font-size': 28, 'line-height': 30, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': 30),
    'h4': ('font-size': 24, 'line-height': 35, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h5': ('font-size': 20, 'line-height': $header-lineheight, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
    'h6': ('font-size': 16, 'line-height': $header-lineheight, 'font-weight': $header-font-weight, 'margin-top': 0, 'margin-bottom': $header-margin-bottom)
  )
);
$header-text-rendering: optimizeLegibility;
$header-small-font-color: $medium-gray;

$paragraph-text-rendering: optimizeLegibility;
$paragraph-font-size: rem-calc(18px);
$paragraph-font-size-small: rem-calc(18px);
$paragraph-font-weight: 400;
$paragraph-lineheight: 1.6;
$paragraph-lineheight-small: 1.6;
$paragraph-margin-bottom: rem-calc(25px);
$paragraph-margin-bottom-small: rem-calc(25px);
//$paragraph-margin-offset: ($paragraph-lineheight - $paragraph-font-size) / 2;
//$paragraph-margin-offset-small: ($paragraph-lineheight-small - $paragraph-font-size-small) / 2;

$small-font-size: 80%;

$wp-caption-font-size: 12px;
$wp-caption-line-height: 16px;
$wp-caption-font-weight: 400;
$wp-caption-font-style: normal;
$wp-caption-text-align: right;
$wp-caption-color: $white;
$wp-caption-background-color: rgba($black, 0.3);
$wp-caption-padding: 5px;
$wp-caption-max-width: 535px;

$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);

$anchor-color: $body-font-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: underline;

$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(9px) auto;

$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: none;
$list-style-position: inside;
$list-side-margin: 0;
$list-nested-side-margin: 0;

$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;

$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;

$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$cite-pseudo-content: '\2014 \0020';

$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $black;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;

// 6. Abide
// --------

$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: get-color(alert);
$form-label-color-invalid: get-color(alert);
$input-error-color: get-color(alert);
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

$accordion-background: transparent;
$accordion-plusminus: true;
$accordion-plusminus-color: $body-font-color;
$accordion-plusminus-position: right;
$accordion-title-font-size: 42px;
$accordion-title-font-weight: normal;
$accordion-title-line-height: 50px;
$accordion-item-color: $body-font-color;
$accordion-item-background-hover: $light-gray;
$accordion-item-vertical-padding: 38px;
$accordion-item-horizontal-padding: rem-calc(5);
$accordion-item-horizontal-padding-plusminus: 1.9rem;
$accordion-item-padding: $accordion-item-vertical-padding $accordion-item-horizontal-padding;
$accordion-content-background: transparent;
$accordion-content-border: 1px solid $black;
$accordion-content-padding: 0px $accordion-item-horizontal-padding $accordion-item-vertical-padding;
$accordion-content-color: $body-font-color;
$accordion-content-font-size: 16px;
$accordion-content-font-weight: normal;
$accordion-content-line-height: 25px;
$accordion-content-margin-offset: ($accordion-content-line-height - $accordion-content-font-size) / 2;

// 8. Accordion Menu
// -----------------

$accordionmenu-padding: $global-menu-padding;
$accordionmenu-nested-margin: $global-menu-nested-margin;
$accordionmenu-submenu-padding: $accordionmenu-padding;
$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $primary-color;
$accordionmenu-item-background: null;
$accordionmenu-border: null;
$accordionmenu-submenu-toggle-background: null;
$accordion-submenu-toggle-border: $accordionmenu-border;
$accordionmenu-submenu-toggle-width: 40px;
$accordionmenu-submenu-toggle-height: $accordionmenu-submenu-toggle-width;
$accordionmenu-arrow-size: 6px;

// 9. Badge
// --------

$badge-background: $primary-color;
$badge-color: $white;
$badge-color-alt: $black;
$badge-palette: $foundation-palette;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(18px);
$breadcrumbs-item-color: #7c7c7c;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: '\e801';
$breadcrumbs-item-separator-item-rtl: '\\';
$breadcrumbs-item-separator-color: #7c7c7c;

// 11. Button
// ----------

$button-font-family: $body-font-family;
$button-font-size: rem-calc(16px);
$button-font-weight: 600;
$button-line-height: rem-calc(18px);
$button-letter-spacing: 0.1em;
$button-text-transform: uppercase;
$button-padding: rem-calc(15px) rem-calc(40px);
$button-padding-small: rem-calc(15px) rem-calc(10px);
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $medium-gray;
$button-background-hover: $black;
$button-color: $black;
$button-color-hover: $white;
$button-border-color: $medium-gray;
$button-border-color-hover: $black;
$button-color-alt: $black;
$button-radius: 0;
$button-hollow-border-width: 1px;
$button-sizes: (
  tiny: rem-calc(12px),
  small: rem-calc(14px),
  default: rem-calc(16px),
  large: rem-calc(18px),
);
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;
$button-min-width: 210px;

@include foundation-button;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;
$buttongroup-radius-on-each: true;

// 13. Callout
// -----------

$callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-padding: 1rem;
$callout-font-color: $black;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 30%;

// 14. Card
// --------

$card-background: transparent;
$card-divider-background: $light-gray;
$card-border: 1px solid $global-border-color;
$card-shadow: none; //0 0px 5px 1px rgba(0,0,0,.1);
$card-border-radius: $global-radius;
$card-padding: 15px 0px 5px;
$card-padding-small: 15px 0px 5px;
$card-margin-bottom: 20px;
$card-font-color: $body-font-color;
$card-title-font-color: $medium-gray;
$card-title-font-color-small: $medium-gray;
$card-title-font-size: rem-calc(14px);
$card-title-font-size-small: rem-calc(14px);
$card-title-line-height: rem-calc(16px);
$card-title-line-height-small: rem-calc(16px);
$card-title-font-weight: normal;
$card-title-font-weight-small: normal;
$card-title-text-transform: uppercase;
$card-title-text-transform-small: uppercase;
$card-desc-font-color: #888;
$card-desc-font-color-small: #888;
$card-desc-font-size: rem-calc(13px);
$card-desc-font-size-small: rem-calc(13px);
$card-desc-line-height: rem-calc(20px);
$card-desc-line-height-small: rem-calc(20px);
$card-desc-font-weight: 400;
$card-desc-font-weight-small: 400;

// 15. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: (
  small: rem-calc(25) - $global-padding,
  medium: rem-calc(25) - $global-padding,
);
$closebutton-offset-vertical: (
  small: rem-calc(30) - $global-padding,
  medium: rem-calc(30) - $global-padding,
);
$closebutton-size: (
  small: rem-calc(21),
  medium: rem-calc(21),
);
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;

// 16. Drilldown
// -------------

$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;
$drilldown-padding: $global-menu-padding;
$drilldown-nested-margin: 0;
$drilldown-background: $white;
$drilldown-submenu-padding: $drilldown-padding;
$drilldown-submenu-background: $white;
$drilldown-arrow-color: $primary-color;
$drilldown-arrow-size: 6px;

// 17. Dropdown
// ------------

$dropdown-padding: $global-menu-vertical-padding 0;
$dropdown-background: $body-background;
$dropdown-border-color: $dark-gray;
$dropdown-border: 1px solid $dropdown-border-color;
$dropdown-font-size: $topbar-font-size;
$dropdown-line-height: $topbar-line-height;
$dropdown-font-weight: $topbar-font-weight;
$dropdown-anchor-color: $white;
$dropdown-anchor-hover-color: $topbar-anchor-hover-color;
$dropdown-anchor-bottomline-color: $dark-gray;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
  tiny: 100px,
  small: 200px,
  large: 400px,
);

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: false;
$dropdownmenu-arrow-color: $topbar-anchor-color;
$dropdownmenu-arrow-hover-color: $topbar-anchor-hover-color;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-arrow-padding: 1.5rem;
$dropdownmenu-min-width: rem-calc(200);
$dropdownmenu-background: $dark-gray;
$dropdownmenu-submenu-background: $dropdownmenu-background;
$dropdownmenu-padding: 0.4rem 1rem;
$dropdownmenu-nested-margin: 0;
$dropdownmenu-submenu-padding: $dropdownmenu-padding;
$dropdownmenu-border: 1px solid $dropdown-border-color;
$dropdown-menu-item-color-active: $dropdown-anchor-hover-color;
$dropdown-menu-item-background-active: transparent;

// 19. Flexbox Utilities
// ---------------------

$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

// 20. Forms
// ---------


$fieldset-border: 1px solid $global-border-color;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(0 0 30 0);
$legend-padding: rem-calc(0 3);
$form-spacing: 1.5rem;
$helptext-color: $black;
$helptext-font-size: rem-calc(16);
$helptext-font-weight: normal;
$helptext-line-height: rem-calc(24);
$helptext-font-style: normal;
$helptext-color: $black;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $global-border-color;
$input-prefix-padding: 1rem;
$form-label-color: $primary-color;
$form-label-font-size: rem-calc(18);
$form-label-font-weight: bold;
$form-label-line-height: rem-calc(24);
$form-label-text-padding-bottom: 6px;
$select-background: $white;
$select-triangle-color: $dark-gray;
$select-radius: $global-radius;
$input-color: $black;
$input-placeholder-color: #ababab;
$input-font-family: inherit;
$input-font-size: rem-calc(18);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border-color: #ccc;
$input-border: 1px solid $input-border-color;
$input-border-focus: 1px solid $dark-gray;
$input-padding: rem-calc(10); //$form-spacing / 2;
$input-shadow: none; //inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: none; //0 0 5px $medium-gray;
$input-cursor-disabled: not-allowed;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $global-radius;

$input-height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

// 21. Label
// ---------

$label-background: $primary-color;
$label-color: $white;
$label-color-alt: $black;
$label-palette: $foundation-palette;
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;

// 22. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 23. Menu
// --------

$menu-margin: 0;
$menu-nested-margin: $global-menu-nested-margin;
$menu-items-padding: $global-menu-padding;
$menu-simple-margin: 1rem;
$menu-item-color-active: $white;
$menu-item-background-active: get-color(primary);
$menu-icon-spacing: 0.25rem;
$menu-state-back-compat: true;
$menu-centered-back-compat: true;
$menu-icons-back-compat: true;

// 24. Meter
// ---------

$meter-height: 1rem;
$meter-radius: $global-radius;
$meter-background: $medium-gray;
$meter-fill-good: $success-color;
$meter-fill-medium: $warning-color;
$meter-fill-bad: $alert-color;

// 25. Off-canvas
// --------------

$offcanvas-sizes: (
  small: 100%,
  medium: 350px,
);
$offcanvas-vertical-sizes: (
  small: 250px,
);
$offcanvas-background: $light-gray;
$offcanvas-shadow: none;
$offcanvas-inner-shadow-size: 0;
$offcanvas-inner-shadow-color: transparent;
$offcanvas-overlay-zindex: 11;
$offcanvas-push-zindex: 12;
$offcanvas-overlap-zindex: 13;
$offcanvas-reveal-zindex: 12;
$offcanvas-transition-length: 0.25s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: transparent;
$maincontent-class: 'off-canvas-content';

// 26. Orbit
// ---------

$orbit-bullet-background: $medium-gray;
$orbit-bullet-background-active: $dark-gray;
$orbit-bullet-diameter: 1.2rem;
$orbit-bullet-margin: 0.1rem;
$orbit-bullet-margin-top: 0.8rem;
$orbit-bullet-margin-bottom: 0.8rem;
$orbit-caption-background: rgba($black, 0.5);
$orbit-caption-padding: 1rem;
$orbit-control-background-hover: rgba($black, 0.5);
$orbit-control-padding: 1rem;
$orbit-control-zindex: 10;

// 26b. Slick
// ---------

$slick-font-size: 17px;
$slick-font-size-small: $slick-font-size;
$slick-font-color: $dark-gray;
$slick-arrow-font-size: 24px;
$slick-arrow-color: $dark-gray;
$slick-arrow-color-small: $slick-arrow-color;
$slick-control-width: 240px;
$slick-control-width-small: $slick-control-width;
$slick-control-margin-top: 15px;
$slick-control-margin-top-small: $slick-control-margin-top;
$slick-control-height: 50px;
$slick-control-background-color: $light-gray;
$slick-font-weight: normal;

// 27. Pagination
// --------------

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: $white;
$pagination-item-color-disabled: $medium-gray;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: true;

// 28. Progress Bar
// ----------------

$progress-height: 1rem;
$progress-background: $medium-gray;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: $global-radius;

// 29. Prototype Arrow
// -------------------

$prototype-arrow-directions: (
  down,
  up,
  right,
  left
);
$prototype-arrow-size: 0.4375rem;
$prototype-arrow-color: $black;

// 30. Prototype Border-Box
// ------------------------

$prototype-border-box-breakpoints: $global-prototype-breakpoints;

// 31. Prototype Border-None
// -------------------------

$prototype-border-none-breakpoints: $global-prototype-breakpoints;

// 32. Prototype Bordered
// ----------------------

$prototype-bordered-breakpoints: $global-prototype-breakpoints;
$prototype-border-width: rem-calc(1);
$prototype-border-type: solid;
$prototype-border-color: $medium-gray;

// 33. Prototype Display
// ---------------------

$prototype-display-breakpoints: $global-prototype-breakpoints;
$prototype-display: (
  inline,
  inline-block,
  block,
  table,
  table-cell
);

// 34. Prototype Font-Styling
// --------------------------

$prototype-font-breakpoints: $global-prototype-breakpoints;
$prototype-wide-letter-spacing: rem-calc(4);
$prototype-font-normal: $global-weight-normal;
$prototype-font-bold: $global-weight-bold;

// 35. Prototype List-Style-Type
// -----------------------------

$prototype-list-breakpoints: $global-prototype-breakpoints;
$prototype-style-type-unordered: (
  disc,
  circle,
  square
);
$prototype-style-type-ordered: (
  decimal,
  lower-alpha,
  lower-latin,
  lower-roman,
  upper-alpha,
  upper-latin,
  upper-roman
);

// 36. Prototype Overflow
// ----------------------

$prototype-overflow-breakpoints: $global-prototype-breakpoints;
$prototype-overflow: (
  visible,
  hidden,
  scroll
);

// 37. Prototype Position
// ----------------------

$prototype-position-breakpoints: $global-prototype-breakpoints;
$prototype-position: (
  static,
  relative,
  absolute,
  fixed
);
$prototype-position-z-index: 975;

// 38. Prototype Rounded
// ---------------------

$prototype-rounded-breakpoints: $global-prototype-breakpoints;
$prototype-border-radius: rem-calc(3);

// 39. Prototype Separator
// -----------------------

$prototype-separator-breakpoints: $global-prototype-breakpoints;
$prototype-separator-align: center;
$prototype-separator-height: rem-calc(2);
$prototype-separator-width: 3rem;
$prototype-separator-background: $primary-color;
$prototype-separator-margin-top: $global-margin;

// 40. Prototype Shadow
// --------------------

$prototype-shadow-breakpoints: $global-prototype-breakpoints;
$prototype-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),
                       0 2px 10px 0 rgba(0,0,0,.12);

// 41. Prototype Sizing
// --------------------

$prototype-sizing-breakpoints: $global-prototype-breakpoints;
$prototype-sizing: (
  width,
  height
);
$prototype-sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);

// 42. Prototype Spacing
// ---------------------

$prototype-spacing-breakpoints: $global-prototype-breakpoints;
$prototype-spacers-count: 3;

// 43. Prototype Text-Decoration
// -----------------------------

$prototype-decoration-breakpoints: $global-prototype-breakpoints;
$prototype-text-decoration: (
  overline,
  underline,
  line-through,
);

// 44. Prototype Text-Transformation
// ---------------------------------

$prototype-transformation-breakpoints: $global-prototype-breakpoints;
$prototype-text-transformation: (
  lowercase,
  uppercase,
  capitalize
);

// 45. Prototype Text-Utilities
// ----------------------------

$prototype-utilities-breakpoints: $global-prototype-breakpoints;
$prototype-text-overflow: ellipsis;

// 46. Responsive Embed
// --------------------

$responsive-embed-margin-bottom: rem-calc(16);
$responsive-embed-ratios: (
  default: 4 by 3,
  widescreen: 16 by 9,
);

// 47. Reveal
// ----------

$reveal-background: $white;
$reveal-width: 600px;
$reveal-max-width: $global-width;
$reveal-padding: $global-padding;
$reveal-border: 1px solid $medium-gray;
$reveal-radius: $global-radius;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($black, 0.7);

// 48. Slider
// ----------

$slider-width-vertical: 0.5rem;
$slider-transition: all 0.2s ease-in-out;
$slider-height: 0.5rem;
$slider-background: $light-gray;
$slider-fill-background: $medium-gray;
$slider-handle-height: 1.4rem;
$slider-handle-width: 1.4rem;
$slider-handle-background: $primary-color;
$slider-opacity-disabled: 0.25;
$slider-radius: $global-radius;

// 49. Switch
// ----------

$switch-background: $medium-gray;
$switch-background-active: $primary-color;
$switch-height: 2rem;
$switch-height-tiny: 1.5rem;
$switch-height-small: 1.75rem;
$switch-height-large: 2.5rem;
$switch-radius: $global-radius;
$switch-margin: $global-margin;
$switch-paddle-background: $white;
$switch-paddle-offset: 0.25rem;
$switch-paddle-radius: $global-radius;
$switch-paddle-transition: all 0.25s ease-out;

// 50. Table
// ---------

$table-background: $white;
$table-color-scale: 5%;
$table-border: 1px solid smart-scale($table-background, $table-color-scale);
$table-padding: rem-calc(8 10 10);
$table-hover-scale: 2%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-is-striped: true;
$table-striped-background: smart-scale($table-background, $table-color-scale);
$table-stripe: even;
$table-head-background: $table-background; //smart-scale($table-background, $table-color-scale / 2);
$table-head-row-hover: $table-background; //darken($table-head-background, $table-hover-scale);
$table-foot-background: smart-scale($table-background, $table-color-scale);
$table-foot-row-hover: darken($table-foot-background, $table-hover-scale);
$table-head-font-color: $body-font-color;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: false;
$table-stack-breakpoint: medium;

// 51. Tabs
// --------

$tab-margin: 0;
$tab-background: $white;
$tab-color: $primary-color;
$tab-background-active: $light-gray;
$tab-active-color: $primary-color;
$tab-item-font-size: rem-calc(12);
$tab-item-background-hover: $white;
$tab-item-padding: 1.25rem 1.5rem;
$tab-content-background: $white;
$tab-content-border: $light-gray;
$tab-content-color: $body-font-color;
$tab-content-padding: 1rem;

// 52. Thumbnail
// -------------

$thumbnail-border: 4px solid $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 53. Title Bar
// -------------

$titlebar-background: $black;
$titlebar-color: $white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $white;
$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0.25rem;

// 54. Tooltip
// -----------

$has-tip-cursor: help;
$has-tip-font-weight: $global-weight-bold;
$has-tip-border-bottom: dotted 1px $dark-gray;
$tooltip-background-color: $black;
$tooltip-color: $white;
$tooltip-padding: 0.75rem;
$tooltip-max-width: 10rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-radius: $global-radius;

// 55. Xy Grid
// -----------

$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
  small: 20px,
  medium: 30px
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;
$xy-block-grid-max: 8;

// 56. Toggle Menu
// -----------

$toggle-menu-background-color: $header-background-color;
$toggle-menu-anchor-color: $topbar-anchor-color;
$toggle-menu-font-size: 26px;
$toggle-menu-line-height: 28px;
$toggle-menu-font-weight: normal;
$toggle-menu-text-transform: inherit;
$toggle-menu-item-vertical-padding: 18px;
$toggle-menu-item-background-color: transparent;
$toggle-menu-item-active-background-color: rgba(0,0,0,0.2);
$toggle-menu-item-border-color: $light-gray;

$toggle-menu-small-background-color: $header-background-color;
$toggle-menu-small-top-padding: 0;
$toggle-menu-small-anchor-color: $topbar-anchor-color;
$toggle-menu-small-font-size: 14px;
$toggle-menu-small-line-height: 16px;
$toggle-menu-small-font-weight: normal;
$toggle-menu-small-item-vertical-padding: 18px;
$toggle-menu-small-item-background-color: transparent;

$toggle-menu-divider-block-color: $light-gray;

$toggle-menu-searchform-height: $header-height-small;

$hamburger-color: $black;
$hamburger-color-open: $black;
$hamburger-width: 26px;
$patty-thickness: 2px;
$patty-distance: $patty-thickness * 3;
$hamburger-height: ($patty-thickness * 3) + ($patty-distance * 2);
$top-patty1: 0;
$top-patty2: $patty-thickness + $patty-distance;
$top-patty3: ($patty-thickness + $patty-distance) * 2;
$toggle-button-padding: $global-menu-vertical-padding;
