footer.content-info {
  max-width: $main-max-width;
  margin: 0 auto;
  position: relative;
  width: 100%;
  background-color: $footer-background;
  color: $footer-font-color;
  z-index: 1005;
  transition: all 0.8s ease;
   
  .footer-container {
    position: relative;
    max-width: $section-max-width;
    @include section-padding; 
    margin-left: auto;
    margin-right: auto; 
    text-align: center;
    padding-top: $section-vertical-padding;
    padding-bottom: $section-vertical-padding;

    @include breakpoint(medium down) {      
      width: 100%;
      padding-top: $section-vertical-padding-small;
      padding-bottom: $section-vertical-padding-small;          
    }        
  }   

  .social-buttons {
    text-align: center;
    padding-top: 5px;
    margin-bottom: 55px;
  
    .social-button {
      color: $dark-gray;
      display: inline-block;
      font-size: 35px;
      line-height: 35px;
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
      margin: 0;
      transition: all 0.3s ease;  
  
      span:before {
        margin: 0 5px;
      }
  
      &:hover {
        color: $footer-anchor-hover-color;
      }
  
      @include breakpoint(medium down) {
        font-size: 50px;
        line-height: 50px;
      } 
    } 

    @include breakpoint(medium down) {
      width: 100%;
      padding-top: 0;
      margin-bottom: 85px;
    }              
  }

  .footer-bottom-wrap {
    position: relative;
    display: block;
    width: 100%; 
    //padding-bottom: 65px;   
  }

  .footer-nav-wrap {
    position: relative;
    display: inline-block;
    margin: 0 auto;

    @include breakpoint(medium down) {
      width: 100%;
    }     
  }

  .footer-nav {
    align-items: center;

    @include breakpoint(medium down) {
      width: 100%;
      flex-direction: column;
    }        
  }

  .copyright, .footer-nav a, .edit-link a {
    color: $footer-anchor-color;
    padding: 0;
    font-family: $footer-font-family;
    font-size: $footer-font-size;
    font-weight: $footer-font-weight;
    line-height: $footer-line-height;
    letter-spacing: $footer-letter-spacing;
    text-transform: $footer-text-transform;
    transition: all 300ms ease-in-out;        
    //padding: $global-menu-vertical-padding 0;
    margin: 0 7px;

    &:hover:not(.copyright) {
      color: $footer-anchor-hover-color;
    }   

    @include breakpoint(medium down) {
      line-height: 26px;
      padding-bottom: 25px;
    }       
  }

  .copyright, 
  .footer-nav li:first-child a {
    @include breakpoint(xlarge) {
      margin-left: 0;
    }
  }
  .footer-nav li:last-child a {
    @include breakpoint(xlarge) {
      margin-right: 0;
    }
  }

  .copyright {
    color: $footer-font-color;
    letter-spacing: 0.100em;
    padding: 0;
    text-align: left;
    text-transform: inherit;
    margin-right: 14px;

    @include breakpoint(medium down) {
      width: 100%;
      padding: 0 0 30px;
      text-align: center;
    }         
  }  

  .edit-link {
    display: inline-block;
  }

  .divider {
    pointer-events: none;
    margin: 0 11px;
    
    >a {    
      font-size: 0;
      line-height: $footer-line-height;
      border-right: 1px solid $footer-font-color;
      padding: 0;      
    } 
    .divider-v-line {
      height: $footer-line-height;
      border-right: 1px solid $footer-font-color;      
    }

    @include breakpoint(medium down) {
      display: none;
    }       
  }  

  .wpml-ls-wrap {
    margin-top: 65px;
    border-top: 1px solid $global_border_color;
    padding-top: 45px;
    text-align: left;

    .wpml-ls {
      display: inline-block;
      text-align: left;
      margin: 0;
      width: auto;

      .wpml-ls-item {
        a {
          font-size: 14px;
          color: #929292;
          background-color: transparent;
          border: none;
          //padding-left: 0;

          .wpml-ls-flag {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            border: 1px solid rgba(255,255,255,0.3);
          }

          &:hover {
            background-color: rgba(0,0,0,.1);
          }
        }
      }
    }

    .wpml-ls-sub-menu {
      .wpml-ls-open & {
        @include breakpoint(medium down) {
          position: relative;
        }         
      }
    }

    @include breakpoint(medium down) {
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }  

    .hide-lang-sel & {
      display: none;
    }    
  } 

}



