
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .inner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > img, 
  > .img,
  > video,
  > .background-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%; 

    > iframe { 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }        
  } 

  &.ie-object-fit {
    background-size: cover;
    background-position: center center;

    > img, 
    > .img{
      opacity: 0;
    }
  }

  &.ie-object-fit-video {
    overflow: hidden;

    > video,
    > .background-video {
      top:50%;
      left:50%;
      height:auto;
      width:auto;
      min-width:100%;
      min-height:100%;
      transform:translate(-50%, -50%);   
    }
  }     
}

@mixin margin-auto {
    margin: { 
        left: auto;
        right: auto;
    }
}

@mixin vertical-padding( $top, $bottom: 0 ) {
  @if $bottom != 0 {
    padding-top: $top;
    padding-bottom: $bottom;
  }
  @else {
    padding-top: $top;
    padding-bottom: $top;  
  }  
}

@mixin section-padding {
  padding-left: $section-inner-padding;
  padding-right: $section-inner-padding;

  @include breakpoint(large down) {
    padding-left: $section-inner-padding-medium;
    padding-right: $section-inner-padding-medium;  
  } 
  @include breakpoint(small only) {
    padding-left: $section-inner-padding-small;
    padding-right: $section-inner-padding-small;
  }
  @media screen and (max-width: 350px) {
    padding-left: $section-inner-padding-tiny;
    padding-right: $section-inner-padding-tiny;
  }
}

@mixin section-padding-left {
  padding-left: $section-inner-padding;

  @include breakpoint(large down) {
    padding-left: $section-inner-padding-medium;  
  } 
  @include breakpoint(small only) {
    padding-left: $section-inner-padding-small;
  }
  @media screen and (max-width: 350px) {
    padding-left: $section-inner-padding-tiny;
  }
}

@mixin section-padding-right {
  padding-right: $section-inner-padding;

  @include breakpoint(large down) {
    padding-right: $section-inner-padding-medium;  
  } 
  @include breakpoint(small only) {
    padding-right: $section-inner-padding-small;
  }
  @media screen and (max-width: 350px) {
    padding-right: $section-inner-padding-tiny;
  }
}

@mixin section-position-left {
  left: $section-inner-padding;

  @include breakpoint(large down) {
    left: $section-inner-padding-medium;  
  } 
  @include breakpoint(small only) {
    left: $section-inner-padding-small;
  }
  @media screen and (max-width: 350px) {
    left: $section-inner-padding-tiny;
  }
}

@mixin section-position-right {
  right: $section-inner-padding;

  @include breakpoint(large down) {
    right: $section-inner-padding-medium;  
  } 
  @include breakpoint(small only) {
    right: $section-inner-padding-small;
  }
  @media screen and (max-width: 350px) {
    right: $section-inner-padding-tiny;
  }
}

@mixin section-vertical-padding {
  padding-top: $section-vertical-padding;
  padding-bottom: $section-vertical-padding;
 
  @include breakpoint(small only) {
    padding-top: $section-vertical-padding-small;
    padding-bottom: $section-vertical-padding-small;
  }
}

@mixin section-padding-top {
  padding-top: $section-vertical-padding;
 
  @include breakpoint(small only) {
    padding-top: $section-vertical-padding-small;
  }
}

@mixin section-padding-bottom {
  padding-bottom: $section-vertical-padding;
 
  @include breakpoint(small only) {
    padding-bottom: $section-vertical-padding-small;
  }
}

@mixin custom-font-styles(
  $font-size: false,  
  $line-height: false, 
  $font-weight: false, 
  $color: false, 
  $margin-offsets: false, 
  $margin-top: 0, 
  $margin-bottom: 0
  ) {
    @if $font-size { font-size: $font-size; }
    @if $line-height { line-height: $line-height; }
    @if $font-weight { font-weight: $font-weight; }
    @if $color != currentColor { color: $color; }

    $margin-offset: ($line-height - $font-size) / 2;
    @if $margin-offsets { 
      margin-top: - $margin-offset;
      margin-bottom: - $margin-offset; 
    }
    @if $margin-top {
      margin-top: $margin-top;
      @if $margin-offsets { 
        margin-top: $margin-top - $margin-offset;
      }
    }
    @if $margin-bottom {
      margin-bottom: $margin-bottom;
      @if $margin-offsets { 
        margin-bottom: $margin-bottom - $margin-offset;
      }
    }        
}

@mixin header-position() {
  @if $header-position-attribute != relative {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @include breakpoint(large down) {
    @if $header-position-attribute-small != relative {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }     
}

@mixin main-margin-top() {
  @if $header-position-attribute != relative {
    margin-top: $header-height;
  }
  @include breakpoint(large down) {
    @if $header-position-attribute-small != relative {
      margin-top: $header-height-small;
    }
  }   
}

@mixin wp-admin-bar-fix( $position, $offset: 0 ) {
  @if $position != relative {
    $narrow-offset: 46px;
    $wide-offset: 32px;
    @if $offset != 0 and type-of($offset) == 'number' {
      $narrow-offset: $narrow-offset + $offset;
      $wide-offset: $wide-offset + $offset;
    }
    .admin-bar & {
      top: $narrow-offset;
      @media screen and (min-width: 783px) {
        top: $wide-offset;
      }
    }
  }
}

@mixin custom-color($color) {
  h1, .like-h1, .h1,
  h2, .like-h2, .h2,
  h3, .like-h3, .h3,
  h4, .like-h4, .h4,
  h5, .like-h5, .h5,
  h6, .like-h6, .h6 {
    color: $color;
    small {
      color: $color;
    }
  }
  a:not(.button):not(.link) {
    color: $color;
    &:hover,
    &:focus {
      color: $color;
    }
  } 
  blockquote {
    &, p {
      color: $color;
    }
  } 
  cite,
  code,
  kbd {
    color: $color;
  }
  color: $color;         
}

@mixin if-direct-parent($parent-selector) {
  $current-sequences: &;
  $new-sequences: ();

  @each $sequence in $current-sequences {
    $current-selector: nth($sequence, -1);
    $prepended-selector: join($parent-selector, $current-selector);
    $new-sequence: set-nth($sequence, -1, $prepended-selector);
    $new-sequences: append($new-sequences, $new-sequence, comma);
  }

  @at-root #{$new-sequences} {
    @content;
  }
}

// better Landscape and portrait visibility
.better-show-for-landscape {
  @media screen and #{breakpoint(large down)} and #{breakpoint(landscape)} {
    display: block !important;
  }
}

.better-hide-for-landscape {
  @media screen and #{breakpoint(large down)} and #{breakpoint(landscape)} {
    display: none !important;
  }
}

.better-show-for-portrait {
  @media screen and #{breakpoint(large down)} and #{breakpoint(portrait)} {
    display: block !important;
  }
}

.better-hide-for-portrait {
  @media screen and #{breakpoint(large down)} and #{breakpoint(portrait)} {
    display: none !important;
  }
}

// loading spinner
.sk-double-bounce {
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  margin: $spinkit-spinner-margin;

  .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $spinkit-spinner-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-doubleBounce 2.0s infinite ease-in-out;
  }

  .sk-double-bounce2 {
    animation-delay: -1.0s;
  }
}

@keyframes sk-doubleBounce {
  0%, 100% { transform: scale(0) }
  50% { transform: scale(1.0) }
}

.init-loader {
  position: absolute !important;
  z-index: 99999999;
  display: block;
  top: 48vh;
  left: calc(50% - #{$spinkit-size} / 2);

  @include breakpoint(medium down) {
    top: 30vh;
  }   
}

.loading-logo {
  display: block;
  width: $spinkit-size; 
  margin: $spinkit-spinner-margin; 
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}