//@font-face {
//  font-family: 'pt_sans';
//  font-display: swap;
//  src: url('../fonts/PTS75F-webfont.eot');
//  src: url('../fonts/PTS75F-webfont.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/PTS75F-webfont.woff') format('woff');
//  font-weight: bold;
//  font-style: normal;
//
//}
//
//
//@font-face {
//  font-family: 'pt_sans';
//  font-display: swap;
//  src: url('../fonts/PTS55F-webfont.eot');
//  src: url('../fonts/PTS55F-webfont.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/PTS55F-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: 'gilroyextrabold';
//  font-display: swap;
//  src: url('../fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
//       url('../fonts/gilroy-extrabold-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

/* Icon Font */
@font-face {
  font-family: 'icon-font';
  font-display: swap;
  src: url('../fonts/icon-font.eot?92328747');
  src: url('../fonts/icon-font.eot?92328747#iefix') format('embedded-opentype'),
       url('../fonts/icon-font.woff2?92328747') format('woff2'),
       url('../fonts/icon-font.woff?92328747') format('woff'),
       url('../fonts/icon-font.ttf?92328747') format('truetype'),
       url('../fonts/icon-font.svg?92328747#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon-font';
    src: url('../font/icon-font.svg?92328747#icon-font') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icon-font";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-arrow-left:before { content: '\e800'; } /* '' */
.icon-arrow-right:before { content: '\e801'; } /* '' */
.icon-arrow-down:before { content: '\e802'; } /* '' */
.icon-dropdown-arrow:before { content: '\e803'; } /* '' */
.icon-scrolldown:before { content: '\e804'; } /* '' */
.icon-check:before { content: '\e805'; } /* '' */
.icon-close:before { content: '\e806'; } /* '' */
.icon-accordeon-open:before { content: '\e807'; } /* '' */
.icon-accordeon-close:before { content: '\e808'; } /* '' */
.icon-location:before { content: '\e809'; } /* '' */
.icon-share-email:before { content: '\e80a'; } /* '' */
.icon-share-fb:before { content: '\e80b'; } /* '' */
.icon-share-whatsapp:before { content: '\e80c'; } /* '' */
.icon-proshop:before { content: '\e811'; } /* '' */
.icon-search:before { content: '\e827'; } /* '' */
.icon-social-facebook:before { content: '\e82d'; } /* '' */
.icon-social-youtube:before { content: '\e82f'; } /* '' */
.icon-social-instagram:before { content: '\e830'; } /* '' */