.wpml-ls-custom-dropdown-click,
.wpml-ls-legacy-dropdown-click {
  width: 15.5em;
  max-width: 100%;

  >ul {
	  position: relative;
	  padding: 0;
	  margin: 0 !important;
	  list-style-type: none;
	}

	.wpml-ls-item {
	  padding: 0;
	  margin: 0;
	  list-style-type: none;
	}	

	a {
	  display: block;
	  text-decoration: none;
	  color: #444;
	  border: 1px solid #cdcdcd;
	  background-color: #fff;
	  padding: 5px 10px;
	  line-height: 1;

		&.wpml-ls-item-toggle {
		  position: relative;
		  padding-right: calc(10px + .7em + .7em);

			//&:after {
			//  content: '';
			//  vertical-align: middle;
			//  display: inline-block;
			//  border: .35em solid transparent;
			//  border-top: .5em solid;
			//  position: absolute;
			//  right: 10px;
			//  top: calc(50% - .175em);
			//}

			.caret-icon {
    		font-size: 7px;
    		padding-left: 10px;				
			}

			.rtl & {
			  padding-right: 10px;
			  padding-left: calc(10px + .7em + .7em);

			  //&:after {
				//  right: auto;
				//  left: 10px;
				//}

				.caret-icon {
    			padding-right: 10px;				
				}				
			}		  
		}	  

		&:hover,
		&:focus {
    	color: #000;
    	background: #eee;			
		}

		span {
		  vertical-align: middle;
		}	  
	}

	.wpml-ls-current-language:hover>a {
	  color: #000;
	  background: #eee;
	}	

	.wpml-ls-sub-menu {
	  visibility: hidden;
	  position: absolute;
	  top: 100%;
	  right: 0;
	  left: 0;
	  border-top: 1px solid #cdcdcd;
	  padding: 0;
	  margin: 0;
	  list-style-type: none;
	  z-index: 101;

		a {
		  border-width: 0 1px 1px 1px;
		}	  
	}

	.wpml-ls-flag {
	  display: inline;
	  vertical-align: middle;
	  width: 18px;
	  height: 12px;

	  +span {
		  margin-left: .4em;

			.rtl & {
			  margin-left: 0;
			  margin-right: .4em;
			}		  
		}
	}	

}

.wpml-ls-wrap {
  text-align: left;

  .wpml-ls {
    display: inline-block;
    text-align: left;
    margin: 0;
    width: auto;

    .wpml-ls-item {
    	width: 100%;
      a {
        font-size: 14px;
        color: #929292;
        background-color: transparent;
        border: none;

        .wpml-ls-flag {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          border: 1px solid rgba(255,255,255,0.3);
        }

        .ls-code {
        	display: none;
        	text-transform: uppercase;
        }

        &:hover {
          background-color: rgba(0,0,0,.1);
        }
      }
    }
  }  

	&.short {
  	.wpml-ls {
  	  .wpml-ls-item {
  	    a {
  	      .ls-name {
  	        display: none;
  	      }
	
  	      .ls-code {
  	      	display: inline;
  	      }

					&.wpml-ls-item-toggle {
						padding-right: 10px;
					}  	      
  	    }
  	  }

  	  .wpml-ls-sub-menu {
  	  	.wpml-ls-item {
  	  	  a {
  	  	    .ls-code {
  	  	    	padding-left: 4px;
  	  	    } 	      
  	  	  }
  	  	}
  	  }
  	}		
	}

  .hide-lang-sel & {
    display: none;
  }    
}

#main-header .wpml-ls-wrap {
	margin-left: 25px;
	.wpml-ls-custom-dropdown-click,
	.wpml-ls-legacy-dropdown-click {
		width: auto;
	}
}

#toggle-menu .wpml-ls-wrap {
  .wpml-ls {
    width: 100%;
  } 

  .wpml-ls-sub-menu {
    .wpml-ls-open & {
       position: relative;        
    }
  }	
}