.search-button {
  text-decoration: none;
  &:hover, &:focus {
    outline: none !important;      
  }  
}

.search-form {
	.search-input-group {
		height: $input-height;

  	@include breakpoint(xlarge) {
  	  height: 85px;   
  	}		
	}

  .search-input {
    position: relative;
    caret-color: $black;
    padding-left: 48px;
    background-color: transparent;
    z-index: 1;
    outline: 0;
    

    &:valid {
      // Hides the label
      background-color: $input-background;
    }
    &:focus {
      background-color: $input-background-focus;
      border: 1px solid transparent;
    }

  	@include breakpoint(xlarge) {
  	  font-size: 32px;
  	  line-height: 48px; 
  	  padding: 40px;
    	padding-left: 68px;
    	margin: 0;
  	}	    
  }

  .search-button  {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 17px;
    display: block;
    padding: 0;
    margin: 0;
    color: $medium-gray;
    font-weight: $input-font-weight;
    font-size: 18px;
    line-height: $input-line-height;
    cursor: pointer;
    border: none;
    outline: none;
    min-width: 0;
    z-index: 3;

  	@include breakpoint(xlarge) {
  		left: 25px;
  	  font-size: 24px;
  	  line-height: 48px;    
  	}	     
  }

  .placeholder-label {
    transition: // not padding
      background 0.2s,
      color 0.2s,
      top 0.2s,
      bottom 0.2s,
      right 0.2s,
      left 0.2s;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
		font-family: $input-font-family;
		font-size: $input-font-size;
		font-weight: $input-font-weight;
		line-height: $input-line-height;    
    color: $input-placeholder-color;
    background-color: $input-background;
    padding: $input-padding;
    padding-left: 48px;
    z-index: 0;

  	@include breakpoint(xlarge) {
  	  font-size: 32px;
  	  line-height: 48px;  
      padding: 16px 40px 0 68px;
      height: 80px; 
  	}	     
  }  
}

.ie-ver-11 .search-form {
  .search-input {
    line-height: 85px;
    height: 85px; 
    padding-top: 0;
    padding-bottom: 0;
  }
}

.search-form-headline {
  @include breakpoint(xlarge) {
    margin-top: 40px;    
  }	
}

.search-section {
  @include breakpoint(large down) {
    padding-top: 15px;    
  }	
}	

.search-popup {

  @include breakpoint(large down) {
    padding: 0;    
  }	
  @include breakpoint(xlarge) {
    border: none;
    background-color: transparent;    
  }	

	.top-wrap {
  	@include breakpoint(xlarge) {
  	  height: 40px;
  	  margin: 0;    
  	} 		
	}

	.section-headline {
  	@include breakpoint(xlarge) {
  	  display: none;    
  	} 		
	}

	.close-button {
  	@include breakpoint(xlarge) {
  	  top: 0;
  	  right: 0;
  	  color: $white;    
  	} 		
	}		

	.search-form {
  	@include breakpoint(large down) {
  	  margin-top: 15px;    
  	}	
    .search-input{
      @include breakpoint(xlarge) {
        border: none; 
      }         
    }		
	}
}
