.input-group {
  position: relative;
  overflow: hidden;
}

.wpcf7 {

	input, 
	textarea, 
	select, 
	button,
	.wpcf7-form-control {
		margin-bottom: 0;
	}

	.wpcf7-form {
		&.sent {
			h1, h2, h3, h4, h5, h6, p {
				display: none;
			}
		}
	}
	
	h3 {
  	margin-top: rem-calc(50 - header-margin-offset(medium, h3));
	
  	@include breakpoint(small only) {
  	  margin-top: rem-calc(50 - header-margin-offset(small, h3));
  	} 

  	&:first-of-type {
  	  margin-top: 0;
  	}  	
	}

	p {
		margin-bottom: $form-spacing;
	}

	.birthday-day,
	.birthday-month,
	.birthday-year {
		width: 100px;
		display: inline-block;
		margin-right: 7px;
	}

	.wpcf7-list-item {
		margin: 0;
		display: block;

		.wpcf7-list-item-label {
		  //display: block;
		  //padding-left: 21px;
		  //text-indent: -21px;
			color: $form-label-color;
			font-size: $form-label-font-size;
			line-height: $form-label-line-height;
			font-weight: normal;		  
		}

		input[type=checkbox], 
		input[type=radio] {
		  width: 20px;
		  height: 13px;
		  padding: 0;
		  margin:0;
		  vertical-align: middle;
		  position: relative;
		  top: -1px;
		  left: -3px;
		}		
	}

	.wpcf7-not-valid-tip {
		@include form-error;
    display: block;
    margin-top: 5px;
    line-height: 1em;		
	}	

	.wpcf7-validation-errors {
  	@include callout-base;
  	@include callout-style($alert-color);		
	}
	.wpcf7-mail-sent-ok {
  	@include callout-base;
  	@include callout-style($success-color);			
	}

	.screen-reader-response {
		@include element-invisible;
	}
}

.ui-datepicker {
	background-color: $white;

	.ui-datepicker-prev {
		padding-right: 15px;
	}
}

.mc4wp-checkbox {
	label {
		font-weight: normal;
	}
}

