.accordion-wrap {
  &.width-narrow {
    max-width: $default-wrap-max-width;
    margin-left: auto;
    margin-right: auto;    
  }  
}

.accordion {
  text-align: left;
  list-style-position: outside;
  margin-top: 0;
  margin-bottom: 0;
}

.accordion-title {
  transition: all 300ms ease-out;
  font-size: $accordion-title-font-size;
  font-weight: $accordion-title-font-weight;
  line-height: $accordion-title-line-height;  
  color: $black;
  border: none;
  text-decoration: none;
  //hyphens: auto;
  border-top: 1px solid $accordion-content-border;

  .is-active > & {
    //padding-bottom: 5px;
  }

  :first-child {
    //border-top: 1px solid $accordion-content-border;
  }
  &:hover,
  &:focus {
    background-color: $accordion-background;
    color: $accordion-item-color;
  }
  @if $accordion-plusminus {
    padding: $accordion-item-vertical-padding $accordion-item-horizontal-padding $accordion-item-vertical-padding $accordion-item-horizontal-padding-plusminus;
    @if $accordion-plusminus-position == 'right' {
      padding: $accordion-item-vertical-padding $accordion-item-horizontal-padding-plusminus $accordion-item-vertical-padding $accordion-item-horizontal-padding; 
    } 

    &::before {
      @if $accordion-plusminus-position == 'left' {
        right: auto;
        left: 0rem;
      }      
      @if $accordion-plusminus-position == 'right' {
        left: auto;
        right: 0rem; 
      } 
      font-family: 'icon-font';
      content: '\e807';
      color: $accordion-plusminus-color;
      background-color: $accordion-background;
      font-size: rem-calc(20);
      width: 21px;
      margin-top: 0;
      top: rem-calc($accordion-item-vertical-padding);
      transition: all .25s ease;
    }
    .is-active > &::before {
      content: '\e808';
    }    
  }
}

.accordion-content {
  border: none;
  color: $accordion-content-color;

  @if $accordion-plusminus {
    padding: 0 $accordion-item-horizontal-padding $accordion-item-vertical-padding $accordion-item-horizontal-padding-plusminus;
    @if $accordion-plusminus-position == 'right' {
      padding: 0 $accordion-item-horizontal-padding-plusminus $accordion-item-vertical-padding $accordion-item-horizontal-padding; 
    } 
  }

  table {
    font-size: $accordion-content-font-size;
    font-weight: $accordion-content-font-weight;
    line-height: $accordion-content-line-height;  
       
    &:last-child {
      margin-bottom: 0;   
    }
  }  

  p, ul, ol, dl, blockquote {
    font-size: rem-calc(24);
    //color: $dark-gray;

    .bg-black & {
      color: $white;
    } 

    &:last-child {
      margin-bottom: 0;   
    }     
  } 
  &.font-size-normal {
    p, ul, ol, dl, blockquote {
      font-size: inherit;
    }
  } 

  ul, ol {
    list-style-position: outside;
    margin-left: 1em;
  
    li:not(.menu-item) {
      margin-bottom: 10px;
    }
  }

  &.has-image {
    .image-wrap {
      padding-right: 20px;

      @include breakpoint(small only) {
        padding-right: 0;
        padding-bottom: 14px;
      }         
    }
    .text-wrap {
      padding-left: 20px;

      @include breakpoint(small only) {
        padding-left: 0;
      }           
    }    
  }
   
}


