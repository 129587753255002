#main-header {
  max-width: $main-max-width;
  position: $header-position-attribute;
  margin: 0 auto;
  width: 100%;
  height: $header-height;
  color: $topbar-anchor-color;
  background-color: $header-background-color;
  transition-property: transform, height, background-color;
  transition-duration: .25s;
  transition-timing-function: ease;  
  @if $header-border-thickness != 0 {
    border-bottom: $header-border-thickness solid $header-border-color;
  } 
  //box-shadow: 0 1px 3px rgba(0,0,0,0.1);   
  z-index: 99995; 

  @include header-position;
  @include wp-admin-bar-fix($header-position-attribute, 0);

  .solid-header & {
    height: $header-height-scrolled;
    background-color: $topbar-background;
    //box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border-bottom: none;
  } 

  &.no-group-menu {
    height: $header-height - rem-calc(30);

    @include breakpoint(large down) {
      height: $header-height-small;
    }
  }

  @include breakpoint(large down) {
    position: $header-position-attribute-small;
    height: $header-height-small;
    background-color: $header-background-color-small;

    @if $header-border-thickness-small != 0 {
      border-bottom: $header-border-thickness-small solid $header-border-color;
    }

    @include header-position;
    @include wp-admin-bar-fix($header-position-attribute-small, 0);

    .solid-header & {
      height: $header-height-small;

      @if $header-border-thickness-small != 0 {
        border-bottom: $header-border-thickness-small solid $header-border-color;
      }
    }     
  }  

  &.toggle-menu-open {
    background-color: $toggle-menu-background-color;
    box-shadow: none;
    border-bottom: $header-border-thickness-small solid rgba(0,0,0,0.1);
  }  

}

.top-bar {
  position: relative;
  max-width: $section-max-width;
  height: $topbar-height;
  background: url($pergament);
  margin-left: auto;
  margin-right: auto;
  transition: all 300ms ease-in-out;
  z-index: 1;

  @include section-padding;

  @include breakpoint(large down) {
    height: $topbar-height-small;
    //transition: all $toggle-menu-transition-duration ease-in-out;
    padding-left: 0;
    padding-right: 0;
  }

  &#top-bar-top {
    height: 30px;
    background-color: $dark-gray; 
    z-index: 2;   
  }

  &#top-bar-bottom {
    height: 80px;   

    @include breakpoint(large down) {
      height: 50px;
    }
  }

  ul {
    height: 100%;
    min-height: 100%;       
    align-items: center;
    background-color: transparent;    
  }

  .top-bar-left {
    position: relative;
    flex-grow: 0;
    z-index: 99996;

    @include breakpoint(large down) {
      display: block;
      margin: 0 auto;      
    }
  }  

  .top-bar-center {
    position: relative;
    height: 100%;
    background: url($pergament);
    @include top-bar-container;
    flex: 1 0 auto;
    padding-left: 120px;
    z-index: 99996;

    @include breakpoint(large down) {
      margin-left: 0;
    }
  }  

  .top-bar-right {
    position: relative;
    @include top-bar-container;
    z-index: 99996;     
  }

  .home-button {
    max-height: 100%;
    margin: 0 auto;
    z-index: 99996;

    a {
      padding: $topbar-logo-spacing;

      .logo-wrap {
        position: relative;
  
        img {
          max-width: $logo-width;
          width: $logo-width; 

          .solid-header & {
            max-width: $logo-width-scrolled;
            width: $logo-width-scrolled; 

            @include breakpoint(large down) {
              max-width: $logo-width-small;
              width: $logo-width-small; 
            }             
          }   

          @include breakpoint(large down) {
            max-width: $logo-width-small;
            width: $logo-width-small; 
          }             
        } 
      }      
    }  
  }

  &#top-bar-top {
    .top-bar-left {
      margin-left: -14px;
  
      @include breakpoint(large down) {
        margin-left: 0;
      }
    }   
     
    .menu-item:not(.is-submenu-item) {
      >a {
        position: relative;
        color: #ababab;
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        background-color: transparent;     
      }
      &:hover,
      &.current-menu-item>a,
      &.current-menu-ancestor>a { 
        color: $white;
        background-color: #565656;                 
      }    
    }
    .dropdown.menu>li.opens-right>.is-dropdown-submenu {
      color: $white;
      background-color: $dark-gray;
    }
  }

  &#top-bar-bottom .menu-item:not(.is-submenu-item):not(.wpml-ls-item) {
    height: 100%;
    min-height: 100%;    
    >a {
      position: relative;
      height: 100%;
      min-height: 100%;      
      color: $topbar-anchor-color;
      font-size: $topbar-font-size;
      line-height: $topbar-line-height;
      font-weight: $topbar-font-weight;
      text-transform: $topbar-text-transform;
      background-color: transparent;
      letter-spacing: 0.125em;
      @if $topbar-anchor-line-thickness != 0 {
        background-image: linear-gradient(90deg, $topbar-anchor-line-color, $topbar-anchor-line-color);
        background-size: 0 $topbar-anchor-line-thickness;
        background-position: 50% $topbar-anchor-line-position;
        background-repeat: no-repeat;
        transition: background-size 300ms ease-in-out;
      }              
      padding: $global-menu-vertical-padding 0;
      margin: 0 $global-menu-horizontal-padding;
      outline: none !important;   

      .solid-header & {        
        padding: rem-calc(16) 0;
      }                              
    }  

    >a:hover,
    &.current-menu-item>a,
    &.current-menu-ancestor>a {
      color: $topbar-anchor-color;
      @if $topbar-anchor-line-thickness != 0 {
        background-size: 100% $topbar-anchor-line-thickness;
      }                   
    } 

    &:hover,
    &.active,
    &.is-active { 
      background-color: $topbar-anchor-hover-background-color;                 
    }     

    &:first-child>a {
      //margin-left: 0;               
    } 
    &:last-child>a {
      //margin-right: 0;            
    }     

    &.is-dropdown-submenu-parent>a {
      padding-right: 5px;

      &::after {
        display: none;
        content: '';
      }            
    } 

    &.divider>a {
      top: 50%;
      transform: translateY(-50%);    
      font-size: 0;
      line-height: 30px;
      border-right: 1px solid $header-border-color;
      padding: 0;             
    } 
  }

  .icon {
    font-size: 5px;
    vertical-align: text-bottom;   
  }  
 
}

.dropdown.menu.is-dropdown-submenu {
  > li {
    > a {
      color: $dropdown-anchor-color;
      &:hover,
      &.active { 
        color: $dropdown-anchor-hover-color;                 
      }      
    }
  }  
}

.menu-popup {
  width: 100%;
  max-width: $section-max-width;
  max-height: calc(100vh - #{$header-height});
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border: none;
  top: $header-height !important;

  .solid-header & {
    top: $header-height-scrolled !important;
    max-height: calc(100vh - #{$header-height-scrolled});
  } 

  @include breakpoint(large down) {
    top: $header-height-small !important;
    max-height: calc(100vh - #{$header-height-small});

    .solid-header & {
      top: $header-height-small !important;
      max-height: calc(100vh - #{$header-height-small});
    }    
  }     
}

.search-popup,
.menu-popup {
  .toggle-menu-open & {
    top: 0 !important;
    min-height: 100vh;
    padding-bottom: 60px;
    overflow-y: scroll;
    background-color: $light-gray;

    @include breakpoint(large down) {
      top: 0 !important;
    } 

    .top-wrap {
      height: $header-height-small;
      background-color: $toggle-menu-background-color;
      margin-bottom: 0; 
      color: $black; 

      .close-button {
        color: $black;
        @include section-position-left;
        margin-left: -8px;
        top: 50%;
        transform: translateY(-50%);   
        width: 30px;

        .icon-close:before {
          content: '\e800';
        }
      }    
    }   

    .section-headline {
      @include absolute-center;
      color: $black;
      font-size: 20px;
      font-family: $body-font-family;
      font-weight: bold;
      text-transform: initial;
      margin: 0;       
    }

    .bottom-wrap {
      background-color: $light-gray;   
    }     
  }  
}

.toggle-menu-popup {
  top: 0 !important;
  display: block;
  background: $dark-gray;
  position: relative;   
  width: 100%;
  min-height: 100vh;
  padding: 0;
  padding-bottom: 60px;
  overflow-y: scroll;
  z-index: 99997;

  .top-wrap {
    width: 100%;
    height: $header-height-small;
    background-color: $dark-gray;
    margin-bottom: 0;  
    position: fixed; 
    top: 0;
    left: 0;
    z-index: 2;  

    .close-button {
      color: $white;
      @include section-position-left;
      top: 50%;
      transform: translateY(-50%); 
      width: 30px;      
    }
  }

  .bottom-wrap {
    background-color: $dark-gray;
    padding-top: $header-height-small;
    z-index: 1;
  }

  .menu {
    display: block;
    
    .menu-item:not(.is-submenu-item) {  
      background-color: $dark-gray;
      width: 100%;
      display: block;
      border-top: 1px solid rgba(255,255,255,0.1);

      &:last-child {
        border-bottom: 1px solid rgba(255,255,255,0.1);           
      }

      a {
        width:100%;
        display: block;
        position:relative;
        text-align: left;
        text-transform: $toggle-menu-text-transform;
        color: $white;
        font-size: $toggle-menu-font-size;
        line-height: $toggle-menu-line-height;
        font-weight: $toggle-menu-font-weight;
        outline: none !important;
        @include vertical-padding($toggle-menu-item-vertical-padding + 12px);
        @include section-padding;                 
      }
      &.current-menu-item,
      &.current-page-ancestor {
        a {
          color: $white;
          background-color: $dark-gray;
        }
      } 

      .icon {
        font-size: 12px;
        position: absolute;
        @include section-position-right;
        top: 50%;
        transform: translateY(-50%);     
      }                 
    }    
  }
}

.reveal-overlay {

  .toggle-menu-open & {
    z-index: 99996;
  }
  .popup-menu-open & {
    z-index: $reveal-zindex;
  }  
  .toggle-menu-popup-open & {
    z-index: 99997;
  }  
}

.home-nav {
  .toggle-menu-open & {
    display: none;
  }  
}


// Toggle Menu
#toggle-menu {
  display: block;
  background: $toggle-menu-background-color;
  position: relative;   
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  overflow-y: scroll;
  z-index: 99995;
  @include wp-admin-bar-fix($header-position-attribute, 0);

  @include breakpoint(large down) {
    @include wp-admin-bar-fix($header-position-attribute-small, 0);
  } 

  .full-off-canvas & {
    padding-top: $header-height-small;
  }

  .divider-block {
    background-color: $toggle-menu-divider-block-color;
  }

  .menu {
    display: block;
    
    .menu-item:not(.is-submenu-item) {  
      background-color: $toggle-menu-item-background-color;
      width: 100%;
      //display: block;
      border-top: 1px solid $toggle-menu-item-border-color;

      &.cpt-product {
        display: none;
      }

      &.current-menu-item {
        border-left: 4px solid $red;
      }
      &.is-menu-item-header {
        background-color: $toggle-menu-background-color;
      }

      &.divider>a {   
        font-size: 0;
        line-height: 0;
        border-bottom: 1px solid $toggle-menu-item-border-color;
        padding: 0;      
      }

      a {
        width: 100%;
        display: block;
        position:relative;
        text-align: left;
        text-transform: $toggle-menu-text-transform;
        color: $toggle-menu-anchor-color;
        font-size: $toggle-menu-font-size;
        line-height: $toggle-menu-line-height;
        font-weight: $toggle-menu-font-weight;
        outline: none !important;
        @include vertical-padding($toggle-menu-item-vertical-padding);
        @include section-padding;   
        padding-right: 35px;  

        .link-title {
          padding-right: 60px;
        }

        .image-wrap {
          min-width: 100px;
          height: 40px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include section-position-right;
          margin-right: 15px; 
          text-align: center;           
      
          img {
            max-height: 100%;
            height: 100%;
            width: auto;   
          }
        }                           
      }
      &.current-menu-item,
      &.current-page-ancestor {
        a {
          color: $toggle-menu-anchor-color;
        }
      }

      &.menu-item-has-children:not(.is-submenu-item),
      &.popup-link-item {
        > a:not(.open)::after {
          @include css-triangle(5px, $toggle-menu-anchor-color, down);
          display: inline-block;
          margin-left: 0.5em;
          margin-bottom: 2px;
        }
        > a.open::after {
          @include css-triangle(5px, $toggle-menu-anchor-color, up);
          display: inline-block;
          margin-left: 0.5em;
          margin-bottom: 2px;
        }   

        > a.popup-link:after {
          border-color: #ababab transparent transparent;
        }        

        .foldable-content {
          transition: height 0.3s ease-in-out;
          height: 0;
          overflow: hidden;
    
          .item-wrapper {
            padding: 0;
          }
    
          &.open {
            height: auto;
          }
        }             
      } 
    }

    .menu-item:not(.is-submenu-item).divider + .menu-item:not(.is-submenu-item).current-menu-item {
      border-top: none;
    }

    &.group-menu {
      background-color: $dark-gray;
      
      .popup-link-item {
        border-top: none;
      }

      .menu-item:not(.is-submenu-item) {
        background-color: $dark-gray;

        a {
          color: #ababab;
          font-size: 20px;
          text-align: center;
        }
      }        
    }

    &.small-menu {
      padding-top: $toggle-menu-small-top-padding;
      background-color: $toggle-menu-small-background-color;

      .menu-item:not(.is-submenu-item) {
        width: 100%;
        display: block;
        border-top: none;
        border-bottom: 1px solid $toggle-menu-item-border-color;
        background-color: $toggle-menu-small-item-background-color;

        a {
          color: $toggle-menu-small-anchor-color;
          font-size: $toggle-menu-small-font-size;
          line-height: $toggle-menu-small-line-height;
          font-weight: $toggle-menu-small-font-weight;
          @include vertical-padding($toggle-menu-small-item-vertical-padding);                 
        }   
      }      
    }

    &.search-menu {
      .menu-item:not(.is-submenu-item) {
        border-bottom: none;
      }        
    }

    .icon {
      font-size: 12px;
      position: absolute;
      @include section-position-right;
      top: 50%;
      transform: translateY(-50%);     
    }     

  }
}

// Toggle Menu Overlay
.toggle-menu-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99994;
  display: none;
  transition: opacity 500ms linear;
  background-color: $black;
  opacity: 0.7;

  .toggle-menu-open &,
  .search-bar-open & {
    display: block;
  }     
}

body.toggle-menu-open { 
  overflow: hidden;
}

.off-canvas.position-left,
.off-canvas.position-right {
  @include breakpoint(small only) {
    width: calc(100% - #{$header-height-small});  
  }

  .full-off-canvas & {
    width: 100%;
  }  
}

// Toggle Menu Button
#toggle-menu-button {
  //display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateY(- 2px);
  color: $hamburger-color;
  z-index: 99995;
  

  &.toggle-left {
    left: 0;
    @include section-padding-left;
  }

  &.toggle-right {
    right: 0;
    @include section-padding-right;
  }

  .hamburger {        
    display: inline-block;
    position: relative; 
    vertical-align: middle;
    width: $hamburger-width;
    height: $hamburger-height;  
  }
  
  .hamburger > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: $hamburger-width;
    height: $patty-thickness;
    background-color: $hamburger-color;
    transition: top 200ms ease-in-out, transform 200ms ease-in-out, background-color 200ms ease-in-out;

    .toggle-menu-open & {
      background-color: $hamburger-color-open;
    }
  }
  
  span#patty1 { top: $top-patty1; }
  span#patty2 { top: $top-patty2; }
  span#patty3 { top: $top-patty3; } 

  .toggle-menu-open & {
    //padding-right: calc((#{$header-height-small} - #{$hamburger-width}) * 0.5);  
    color: $hamburger-color-open; 
  }

  .no-touch & {
    &:hover {
        span#patty1 { top: $top-patty1 - 2px; }
        span#patty2 { top: $top-patty2; }
        span#patty3 { top: $top-patty3 + 2px; }
    } 
  }  

  &.active {
    span#patty1 { transform: rotate(-45deg); top: $top-patty2 !important; }
    span#patty2 { display: none; }
    span#patty3 { transform: rotate(45deg); top: $top-patty2 !important; }               
  }   
}

