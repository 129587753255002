// Head Section
// --------------
.head-section {
	padding-top: 0;
	padding-bottom: 0;
	height: auto;

	.section-headline-wrap {
		max-width: 1000px;
		margin: 0 auto;
	}
	
	.image-wrap,
	.background-video {
		max-height: 100%;
		height: 100%;
	}

	.head-section-slider {
		.image-wrap {
			img.loaded {
				animation: slider-scale 50s linear infinite;
			}
		}
	}

	&.bg-black {
		@include custom-color($white);
	}

	&.bg-style-color {
		.section-headline-wrap {
			padding: 70px 0;
			@include section-padding;
		}
	}

	&.bg-style-image,
	&.bg-style-video {
		overflow: hidden;

		.section-headline,
		.scrolldown-button {
			color: $white;
		}

		.section-headline-wrap {
			position: absolute;
			z-index: 3;

			&.text-center {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);			
			}
			&.text-left {
				top: 80px;
				left: 70px;
				padding-right: 70px;

				@include breakpoint(small only) {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;	
					@include section-padding;
				}									
			}
		}
	}

	&.smallheight {

		&.bg-style-image,
		&.bg-style-video {		
			height: 400px;

			@include breakpoint(small only) {
				height: 330px;
			}					
		}

		.image-wrap {
			&.aspect-ratio {
				@include breakpoint(medium) {
					@include aspect-ratio(1200, 400);
				}
				@include breakpoint(small only) {
					@include aspect-ratio(375, 330);
				}
			}			
		}				
	}

	&.mediumheight {
		height: calc(100vh - #{$header-height} - 100px);	

		.image-wrap {
			&.aspect-ratio {
				@include breakpoint(medium) {
					@include aspect-ratio(1200, 600);
				}
				@include breakpoint(small only) {
					@include aspect-ratio(375, 330);
				}
			}			
		}	

		@include breakpoint(small only) {
			height: 330px;
		}					
	}

	&.fullheight {
		height: calc(100vh - #{$header-height});

		.image-wrap {
			&.aspect-ratio {
				@include breakpoint(medium) {
					@include aspect-ratio(1200, 600);
				}
				@include breakpoint(small only) {
					@include aspect-ratio(375, 330);
				}
			}			
		}	

		@include breakpoint(small only) {
			height: 330px;
		}						
	}				
}

@keyframes slider-scale {
  from {
    transform: scale(1)
  }
  50% {
    transform: scale(1.2)
  }
  to {
    transform: scale(1)
  }
}

// Standard Section
// --------------
.standard-section {
	&.bg-black {
		@include custom-color($white);
	}

	h1 {
		@include breakpoint(small only) {
			font-size: rem-calc(50px);
    	margin-bottom: 1.75rem;			
		}
	}

	.item-cell {
		&.text-center,
		&.image_item {
			display: flex;
			flex-direction: column;
  		//align-items: center;
  		//justify-content: center;	
		}

		&.cs-45 {
			@include breakpoint(large) {
				width: 45%;
			}
		}
		
		&.cs-55 {
			@include breakpoint(large) {
				width: 55%;
			}
		}		

		&.width-narrow {
			max-width: $default-wrap-max-width;
			margin-left: auto;
			margin-right: auto;			
		}
	}

	.text-item-wrap {
		@include section-vertical-padding;
		@include section-padding;

		.link {
			font-size: 20px;

			&.standard {
				color: $red;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.item-cell {
		&.text-center {
			.text-item-inner-wrap {
  			max-width: $headline-wrap-max-width;
  			margin-left: auto;
  			margin-right: auto; 				
			}
		}
	}

	.image-item-wrap {

		.image-wrap {
			margin-left: auto;
			margin-right: auto;
		}

		&.padding-top {
			@include section-padding-top;
		}
		&.padding-left {
			@include section-padding-left;
		}
		&.padding-right {
			@include section-padding-right;
		}
		&.padding-bottom {
			@include section-padding-bottom;
		}	

		&.has-max-width {
			@include breakpoint(small only) {
				@include section-padding-left;
				@include section-padding-right;
			}			
		}
	}	

	.section-frame {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
  	padding-top: $section-vertical-padding;
  	padding-bottom: $section-vertical-padding;
		@include section-padding;
		z-index: 1;

  	@include breakpoint(medium down) {
  	  padding-top: $section-vertical-padding-small;
  	  padding-bottom: $section-vertical-padding-small;
  	}

		.section-frame-inner {
			position: relative;
			width: 100%;
			height: 100%;
			border: 5px solid $medium-gray;

	  	&.fr-red { border-color: $red !important; }
	  	&.fr-white { border-color: $white !important; }
	  	&.fr-medium-gray { border-color: $medium-gray !important; }
	  	&.fr-black { border-color: $black !important; }
	  	&.fr-transparent { border-color: transparent !important; }		
		}
	}	

	&.has-section-frame.top-bottom {
		.standard-item-wrap {
			position: relative;
			padding: 125px 0 115px 0;
			z-index: 2;

  		@include breakpoint(medium down) {
  		  padding: 100px 0 90px 0;
  		}			
		}

		#item-index-1 .text-item-wrap {
			//padding-top: 0;
		}
		#item-index-2 .text-item-wrap {
			//padding-bottom: 0;
		}
	}

	&.has-section-frame:not(.top-bottom) {
		.standard-item-wrap {
			position: relative;
			padding: calc(5 + #{$section-vertical-padding} * 2) calc(5 + #{$section-inner-padding} * 2);
			z-index: 2;

  		@include breakpoint(large down) {
  		  padding: calc(5 + #{$section-vertical-padding-small} * 2) calc(5 + #{$section-inner-padding-medium} * 2);  
  		}
  		@include breakpoint(medium down) {
  		  padding: calc(5 + #{$section-vertical-padding-small} * 2) calc(5 + #{$section-inner-padding-medium} * 2);
  		}
  		@include breakpoint(small only) {
  		  padding: calc(5 + #{$section-vertical-padding-small} * 2) calc(5 + #{$section-inner-padding-small} * 2);
  		}
  		@media screen and (max-width: 350px) {
  		  padding: calc(5 + #{$section-vertical-padding-small} * 2) calc(5 + #{$section-inner-padding-tiny} * 2);
  		}  						
		}

		#item-index-1 .text-item-wrap {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 0;
  		@include breakpoint(large down) {
  		  padding: 0 0 $section-vertical-padding-small 0;  
  		}			
		}
		#item-index-2 .text-item-wrap {
			padding-top: 0;
			padding-bottom: 0;			
			padding-right: 0;
  		@include breakpoint(large down) {
  		  padding: $section-vertical-padding-small 0 0 0;  
  		}			
		}
	}

	&.has-text-frame {
		.text-item-inner-wrap {
			padding: 50px 35px 40px;
			border: 5px solid $medium-gray;

  		@include breakpoint(large down) {
  		  padding: 40px 25px 30px;  
  		} 
  		@include breakpoint(small only) {
  		  padding: 30px 15px 20px;
  		}
  		@media screen and (max-width: 350px) {
  		  padding: 25px 10px 15px;
  		}

	  	&.fr-red { border-color: $red !important; }
	  	&.fr-white { border-color: $white !important; }
	  	&.fr-medium-gray { border-color: $medium-gray !important; }
	  	&.fr-black { border-color: $black !important; }	  					
		}
	}

	&.has-text-frame:not(.top-bottom) {
		#item-index-1 .text-item-wrap {
			@include breakpoint(large) {
				padding-right: 0;
			}
		}
		#item-index-2 .text-item-wrap {
			@include breakpoint(large) {
				padding-left: 0;
			}
		}		
	}	

	&.font-group-color {
		ul {
			list-style: none;

			li::before {
				content: "•"; 
				color: $medium-gray;
			  display: inline-block; 
			  width: 1em;
			  margin-left: -1em;
			  font-weight: bold;
			}			
		}
		.text-item-wrap {
			.link {
				&.standard {
					color: $body-font-color;
				}
			}
		}				
	}

	//&:not(.top-bottom) {
	//	.image-item-wrap {						
	//		@include breakpoint(small only) {
	//			@include section-padding-left;
	//			@include section-padding-right;
	//		}
	//	}		
	//}

	&.has-divider {
		.item-cell {
			&.cs-50 {
				@include breakpoint(medium) {
					width: calc(50% - 7px);
				}
			}
	
			&.cs-45 {
				@include breakpoint(medium) {
					width: calc(45% - 7px);
				}
			}
			
			&.cs-55 {
				@include breakpoint(medium) {
					width: calc(55% - 7px);
				}
			}		
		}		
	}
	.divider-wrap {
		display: flex;
  	flex-direction: column;		
	}
	.divider-block-vertical {
		position: relative;
	  width: $divider-block-height;  
		height: 100%;
		flex: 1;

		@include breakpoint(small only) {
		  width: 100%;
		  max-width: $section-max-width;
		  margin-left: auto;
		  margin-right: auto;   
			height: $divider-block-height;
		}		
	}	
}

// Tile-Grid Section
// --------------
.tile-grid-section {

	.cell-wrapper {
		width: 100%;

		@include breakpoint(medium) {
			width: calc(50% - 7px);
		}

		.divider-wrap {
			@include breakpoint(medium) {
				float: left;
				height: calc(50% - 14px);
			}			
		}
		.divider-block-horizontal {
			@include breakpoint(medium) {
				float: left;
			}			
		}		
	}

	.item-cell {
		position: relative;
		overflow: hidden;

		&:after {
		  content: "";
		  display: block;
		  padding-top: 100%;
		}

		@include breakpoint(small only) {
			margin-bottom: 14px;
		}

		&.text-gray {
			@include custom-color(#6b6b6a);
  		.link {
  		  color: #6b6b6a;
  		  &:hover,
  		  &:focus {
  		    color: #6b6b6a;
  		  }
  		}			
		}
		&.text-white {
			@include custom-color($white);
		}	

		&.big-item {
			.fg-image-wrap {
				padding: 80px 10px 0 10px;
				padding-top: calc(4.2rem + 1vw);

				@include breakpoint(medium only) {
					padding-top: calc(2.2rem + 1vw);
				}	
				@include breakpoint(small only) {
					padding-top: 35px;
				}									
			}		

			.centered-text .item-text {
				font-size: calc(3.8rem + 1vw);

				@include breakpoint(medium only) {
					font-size: calc(3.0rem + 1vw);
				}
				@include breakpoint(small only) {
					font-size: 50px;
				}										
			}	
	
			.image-text .item-text {
				font-size: calc(1.8rem + 1vw);

				@include breakpoint(medium only) {
					font-size: calc(1.2rem + 1vw);
				}				
			}			
		}	

		&.small-item {
			@include breakpoint(medium) {
				width: calc(50% - 7px);
				float: left;
			}		
				
			.fg-image-wrap {
				padding: 80px 10px 0 10px;
				padding-top: calc(1.7rem + 1vw);

				@include breakpoint(small only) {
					padding-top: 60px;
				}				
			}	

			.centered-text .item-text {
				font-size: calc(1.4rem + 1vw);

				@include breakpoint(medium only) {
					font-size: calc(0.7rem + 1vw);
				}	
				@include breakpoint(small only) {
					font-size: 50px;
				}								
			}	
	
			.image-text .item-text {
				font-size: calc(1.2rem + 1vw);

				@include breakpoint(medium only) {
					font-size: calc(0.4rem + 1vw);
				}
				@include breakpoint(small only) {
					font-size: 16px;
				}				
			}			
		}			
	}

	.item-cell-content {
	  position: absolute;
	  width: 100%;
	  height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;	  
	}	

	.image-wrap {

		&.bg-image-wrap {
			img {
				transition: all .3s linear;
			}
		}

		&.fg-image-wrap {
			max-width: 70%;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;		
			z-index: 2;	

			img {
				transition: all .3s linear;
			}			
		}

		&.aspect-ratio {
			@include aspect-ratio(1, 1);
			position: absolute;
		}	
	}	

	.bg-color-overlay {
	  position: absolute;
	  top: 0;
	  left:0;
	  bottom: 0;
	  background-size: cover;
	  max-width: 100%;
	  width: 100%;
	  height: 100%;
	  opacity: 1;
	  z-index: 1;
	}
	.bg-color-overlay:after {
	    content: "";
	    display: block;
	    height: 100%;
	    width: 100%;
	    opacity: 0;
	    background: rgba(0,0,0,.2);
	    transition: all .3s linear;
	    top: 0;
	    left: 0;
	    position: absolute;
	}	

	.text-wrap {
		position: relative;
		padding: 20px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		hyphens: auto;
		z-index: 2;

		.item-text {
			margin: 0;
		}

		&.centered-text {
			display: flex;
			flex-direction: column;
  		//align-items: center;
  		justify-content: center;
  		width: 100%;
  		height: 100%;	
		}	

		@include breakpoint(large only) {
			padding: 10px 15px;
		}
		@include breakpoint(medium only) {
			padding: 10px;
		}
		@include breakpoint(small only) {
			padding: 10px;
		}	
	}

	.link-wrap {
		position: absolute;
		width: 100%;
		bottom: 0;
		text-align: center;
		pointer-events: none;
		z-index: 2;

		.link {
			//font-weight: bold;
			font-size: calc(0.3rem + 1vw);
			pointer-events: auto;

			@include breakpoint(medium only) {
				font-size: calc(0.4rem + 1vw);
			}
			@include breakpoint(small only) {
				font-size: 16px;
			}

			&.standard {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}		
	}

	.item-link {
		text-decoration: none;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  width: 100%;
	  height: 100%;

		&:hover {
			.bg-image-wrap {
				img {
  			  transform: scale(1.05,1.05);
  			}
			}
			.fg-image-wrap {
				img {
  			  transform: scale(1.05,1.05);
  			}
			}			
			.bg-color-overlay:after {
			  opacity: 1;
			}
		}		
	}

	.divider-wrap {
		display: flex;
  	flex-direction: column;

		@include breakpoint(small only) {
		  display: none;
		}  			
	}
	.divider-block-vertical {
		position: relative;
		background-color: $divider-block-color;		
	  width: $divider-block-height;  
		height: 100%;
		flex: 1;		
	}	
	.divider-block-horizontal {
  	
		@include breakpoint(small only) {
		  display: none;
		}  			
	}

	&.style-1-1,
	&.style-1-1-padding {
		.link-wrap {   		
			padding: 0 10px 53px;
			padding-bottom: calc(2.5rem + 1vw);

			@include breakpoint(medium only) {
				padding-bottom: calc(1.0rem + 1vw);
			}
			@include breakpoint(small only) {
				padding-bottom: 20px;
			}								
		}
	}

	&.style-1-4 {
		.link-wrap {   		
			padding: 0 10px 34px;
			padding-bottom: calc(1.25rem + 1vw);

			@include breakpoint(medium only) {
				padding-bottom: calc(0.4rem + 1vw);
			}
			@include breakpoint(small only) {
				padding-bottom: 20px;
			}										
		}
	}	
}

// Image Gallery
// --------------
.image-gallery {
	&.bg-black {
		@include custom-color($white);
	}

	.image-wrap {
		img {
			max-width: 660px;
			width: 660px;

			@include breakpoint(small only) {
				max-width: 100%;
				width: 100%;
			}			
		}
	}	
	&.normal-mode {
		.image-wrap {
			img {
				max-width: 100%;
				width: 100%;			
			}
		}			
	}

	.section-frame {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
  	padding-top: $section-vertical-padding;
  	padding-bottom: $section-vertical-padding;
		@include section-padding;
		z-index: 1;

  	@include breakpoint(medium down) {
  	  padding-top: $section-vertical-padding-small;
  	  padding-bottom: $section-vertical-padding-small;
  	}

		.section-frame-inner {
			position: relative;
			width: 100%;
			height: 100%;
			border: 5px solid $medium-gray;
		}
	}	

	&.has-section-frame {
		.section-inner-wrap {
			position: relative;
			padding: calc(5 + #{$section-vertical-padding}) 0;
			z-index: 2;

  		@include breakpoint(medium down) {
  		  padding: calc(5 + #{$section-vertical-padding-small}) 0;
  		}		

  		.section-headline-wrap {
				padding: 0 calc(5 + #{$section-inner-padding} * 2);
	
  			@include breakpoint(large down) {
  			  padding: 0 calc(5 + #{$section-inner-padding-medium} * 2);  
  			}
  			@include breakpoint(medium down) {
  			  padding: 0 calc(5 + #{$section-inner-padding-medium} * 2);
  			}
  			@include breakpoint(small only) {
  			  padding: 0 calc(5 + #{$section-inner-padding-small} * 2);
  			}
  			@media screen and (max-width: 350px) {
  			  padding: 0 calc(5 + #{$section-inner-padding-tiny} * 2);
  			}    			
  		}	
		}
	}	
}

// Grid Section
// --------------
.grid-section {
	&.bg-black {
		@include custom-color($white);
	}
	.section-headline-wrap {
		margin-bottom: 0; //80px;
		@include section-padding-bottom;

		@include breakpoint(small only) {
			margin-bottom: 0; //65px;
		}			
	}

	.image-wrap {
		display: flex;
		flex-direction: column;
  	justify-content: center;
	  margin: 0 auto 15px;	 
	  display: block; 
	}

	.text-wrap {
		padding-left: 25px;
		padding-right: 25px;

		p, ul, ol, dl, table, blockquote {
			margin-bottom: 15px;
			@include custom-font-styles(24px, 38.4px, false, false, true, 0, 15px);
		}

		@include breakpoint(small only) {
			padding-left: 25px;
			padding-right: 25px;
		}			
	}

	.button-wrap {
		margin-top: 0;
	}

	.grid-wrapper {
    margin-top: 0;
    margin-bottom: -50px;
		justify-content: center;		

		.item-wrap {
			margin-top: 0;
			margin-bottom: 50px;
		}
	}

	.slider-wrapper {
		margin-top: 0; //85px;

		.item-wrap {
			margin-top: 0;
			padding-left: 37px;
			padding-right: 37px;

			@include breakpoint(small only) {
				padding-left: 0px;
				padding-right: 0px;
			}			
		}
	}	
}

// Navigation Section
// --------------
.navigation-section {
	padding-top: 25px;
	padding-bottom: 15px;

	.section-inner {
		@include breakpoint(medium down) {
    	padding-right: 0;
		}			
	}

	.navigation-wrapper {
		text-align: center;

		@include breakpoint(medium down) {
			text-align: left;
			display: flex;
    	flex-flow: row;
		}			
	}

	.item-wrap,
	.v-divider {
		display: inline-block;
		margin: 0 18px;

		@include breakpoint(medium down) {
			margin: 0;
			flex: 0 0 auto;
			width: 18%;
		}
		@media screen and (max-width: 768px) {
			width: 28%;
		}	
		@include breakpoint(small only) {
			width: 39%;
		}			
	}

	.item-wrap {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.image-wrap {
		//display: flex;
		//flex-direction: column;
  	//justify-content: center;
	  margin: 0 auto;
	  max-width: 50px;	  
	}

	.text-wrap {
		font-size: 18px;
		margin: 15px auto 0;			
	}

	.v-divider {
		position: relative;
    width: 1px;
    height: 75px;
		background-color: $medium-gray;		
	}

}

// Service Section
// --------------
.service-section {

	.section-inner {
		@include breakpoint(medium down) {
    	padding-right: 0;
		}			
	}

	.service-wrapper {
		display: flex;
		justify-content: center;	

		@include breakpoint(medium down) {
			display: block;
			text-align: center;
		}				
	}

	.item-wrap {
		display: flex;
		text-align: left;
		text-decoration: none;
		padding: 0 75px;
		width: 33%;

		&:hover {
			text-decoration: underline;
		}

  	&:nth-child(2) {
  		@include breakpoint(large) {
  	  	border-left: 1px solid $medium-gray;  
  	  }  
  	}
  	&:nth-child(2):not(:last-child) {
  		@include breakpoint(large) {
  	  	border-right: 1px solid $medium-gray;
  	  }    
  	}	 

  	&:first-child:not(:last-child) {
  		@include breakpoint(large) {
  	  	padding-left: 0;  
  	  }  
  	}
  	&:last-child:not(:first-child) {
  		@include breakpoint(large) {
  	  	padding-right: 0;   
  	  } 
  	}	  		

		@include breakpoint(medium down) {
			padding: 0 45px 35px;
			width: 100%;
			border: none;
		}	

  	&:last-child {
			@include breakpoint(medium down) {
				padding-bottom: 0;
			}
  	}				 			
	}

	.image-wrap {
	  min-width: 35px;	
	  width: 35px;  
	}

	.text-wrap {
		font-size: 20px;
		padding-left: 25px;		

		.desc {
			color: #808080;
		}	
	}

	.icon-arrow-right {
		color: $red;	
		&:before {
			display: inline;
		}
	}

}

// More Posts Section
// --------------
.more-posts-section {

	.section-headline-wrap {
		margin-bottom: 80px;

		@include breakpoint(small only) {
			margin-bottom: 65px;
		}			
	}

	.post-item {
		padding-bottom: 25px;
		border-bottom: 1px solid $global_border_color;
		margin-bottom: 25px;

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}		
	}

	.updated {
		color: #888;
	}

	.item-link {
		display: block;
		text-decoration: none;

		.item-top {
			width: 100%;
			margin-bottom: 15px;
		}	
	
		.media-object {
			.media-object-section:first-child {
			  padding-left: 0;
			}			

			.image-wrap {
				overflow: hidden;
				width: 230px;
	
				@include breakpoint(small only) {
				  width: 90px;
				}	
	
				&.aspect-ratio {
					@include breakpoint(medium) {
						@include aspect-ratio(230, 129);
					} 
					@include breakpoint(small only) {
					  @include aspect-ratio(180, 180);
					}	
				}	

    		img {
    			position: relative;
    		  transition: all .3s linear;
    		  text-indent: -10000px;
    		  max-height: 100%;
    		  width: inherit;      
    		  margin: 0 auto;
    		}							
			}
		}

  	&:hover .image-wrap img {
  	  transform: scale(1.05,1.05);
  	}

		.item-bottom {
			width: 100%;
		}			
	}
}

// Buy Now Section
// --------------
.buy-now-section {

	.section-headline-wrap {
		max-width: 1000px;
		margin: 0 auto;		
		margin-bottom: 80px;

		@include breakpoint(small only) {
			margin-bottom: 65px;
		}			
	}

  .widget-wrap {
    @include breakpoint(medium down) {
      margin-bottom: 15px;
    }    	
    @include breakpoint(small only) {
      padding-right: 0;
      padding-left: 0;

    }     
  }	

}

// Gunfinder
// --------------
.gfs-widget {
	font-family: $body-font-family;
  font-size: $global-font-size;
  font-weight: $global-weight-normal;
  line-height: $global-lineheight;	

	.gfs-title {
    display: none; 	
	}

	.gfs-item {
		padding: 20px;
		margin-bottom: 10px;
		background-color: $white;
		display: flex;

  	@include breakpoint(large down) {
  		//margin-top: 10px;
  	  //@include section-padding;  
  	  flex-direction: column;  
  	}		
  	@include breakpoint(small only) {
  	  padding: 10px;
  	}  	

  	&.hidden {
  		display: none;
  	}
	}

	.gfs-picture {
    width: 120px;
    min-width: 120px;
    margin-right: 20px;
		a {
			width: 100%;
			img {
				width: 100%;
			}
		}
  	@include breakpoint(large down) {
  		margin-bottom: 15px;  
  	}		
	}

	.gfs-left,
	.gfs-right {
		padding-right: 20px;
	}

	.gfs-details {
		flex-grow: 1;
		padding-right: 0;

		a.gfs.gfs-truncate,
		.gfs-extra,
		.gfs-header a {
			color: $black;
			text-decoration: none;
			font-weight: bold;
  		font-size: rem-calc(20);
  		line-height: rem-calc(23);
  		margin-bottom: 8px;
		
  		@include breakpoint(small only) {
  		  font-size: rem-calc(18);
  		  line-height: rem-calc(21);
  		}  
		}

		.button-wrap {
			margin-top: 10px;
			margin-right: 0;
		}

		.gfs-source {
			hyphens: auto;
		}

		.gfs-extra {
			color: #737373;

  		@include breakpoint(large down) {
  		  margin-top: 5px;    
  		}			
		}
		.gfs-price {
			color: $black;
			font-weight: bold;
			margin-top: 10px;
		}

		.gfs-url,
		.gfs-description {
			display: none;
		}		
	}

	.gfs-extras {
		display: none;
		//min-width: 200px;

		.button.shop,
		.popup {
			display: none;
		}

		.button.out {
			margin-bottom: 0;
		}		

  	@include breakpoint(large down) {
  	  margin-top: 10px;    
  	}		
  	@include breakpoint(small only) {
  	  min-width: 0;
  	}   	
	}

	.gfs-more {
		display: none;
	}

	.gunfinder-button-wrap {
		margin-top: 23px;
	}
}

// Sub Modules
// --------------
.image-block-wrap {

	.image-wrap {
		margin-left: auto; 
		margin-right: auto;
	
		&.aspect-ratio {
			@include breakpoint(medium) {
				@include aspect-ratio(910, 505);
			} 
			@include breakpoint(small only) {
			  @include aspect-ratio(375, 230);
			}	
		}				
	}		

	.slider-control {
		display: inline-block;
		left: 50%;
    transform: translateX(-50%);
		margin-bottom: $slick-control-margin-top;
	}
}

// Dealer Section
// --------------
.location-map-section {
  height: 100%;

  @include breakpoint(medium down) {
      height: auto;
      max-height: none;
  }

	.section-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;		
		z-index: 2;
		pointer-events: none;

	  @include breakpoint(medium down) {	   
    	position: relative;
	    width: 100%;
    	padding-left: 0;
    	padding-right: 0; 
	  }		 	
	}

	#map {
	  position: relative;
	  width: 100%;
  	max-width: $section-max-width;
  	margin-left: auto;
  	margin-right: auto; 	  
	  min-height: 1000px;
	  z-index: 1;

	  @include breakpoint(medium down) {
	    min-height: auto;
	    width: 100%;
	    margin-top: 30px;
	    margin-bottom: 35px;
	    border-top: 1px solid $light-gray;
	    border-bottom: 1px solid $light-gray;
	  }  
	}
	
	.search-box {
	  position: relative;
	  z-index: 99990;
	  top: 93px;
  	left: 0;
	  background: url($pergament);
	  width: 370px;
    pointer-events: all;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
  
	  @include breakpoint(medium down) {
	    top: 0;
	    left: 0;
	    background: $white;
	    width: 100%;
	    min-height: 170px;
	    box-shadow: none;
	  }	  

	  .search-box-top {
	    padding-top: 39px;

	    @include breakpoint(medium down) {
	      background-color: $white;
	    }	 	    		  	
	  }

	  .search-box-bottom {
	    //padding-bottom: 39px; 

	    @include breakpoint(medium down) {
	    	padding-bottom: 25px;
	      background-color: $light-gray;
	    }	 	    		  	
	  }	  

	  .search-box-headline-wrap,
	  .search-form-wrap,
	  .search-reset-wrap,
	  .search-radius-wrap,
	  .search-result-text-wrap {
	    padding-left: 30px;
	    padding-right: 30px;
	    margin-bottom: 25px;

	  	@include breakpoint(small only) {
	    	padding-left: $section-inner-padding-small;
	    	padding-right: $section-inner-padding-small;
	  	}	 		    
	  	@media screen and (max-width: 350px) {
	    	padding-left: $section-inner-padding-tiny;
	    	padding-right: $section-inner-padding-tiny;
	  	}	 		  	
	  }	  

	  label {
	  	padding-bottom: $form-label-text-padding-bottom;	 
	  	font-weight: normal; 	
	  }

		.search-box-headline {
	    @include breakpoint(medium down) {
	      text-align: center;
	    }	 			
		}
		.search-form-wrap {
			padding-bottom: 35px;

	    @include breakpoint(medium down) {
	      padding-bottom: 55px;
	    }	 			
		}

		.group-select-wrap {
			display: none;
		}

	  .search-reset-wrap {
	  	display: none;
	  	padding-bottom: 35px;

			.looked-for-wrap {
        margin-bottom: 15px;

				.looked-for-text {
					color: #ababab;
				}

				.looked-for-result {
					font-weight: bold;
				}				
			}
      .reset-text-wrap {
      	color: #6d6d6d;
        background-color: #f2f2f2;
        border-radius: 9px;
        padding: 1px 8px;
        display: inline-block;
        cursor: pointer;

        .icon {
    			font-size: 12px;
    			vertical-align: middle;
        	display: inline-block;            	
        }
        .item-text {
    			vertical-align: middle;
        	display: inline-block;            	
        }            
      }	  	
	    @include breakpoint(medium down) {
	      text-align: center;
	    }	      
	  }

	  .search-result-text-wrap {
	  	padding-top: 35px;
	  	border-top: 1px solid $light-gray;
	  	font-size: 20px;

			.result-text {
				margin-bottom: 0;
			}
	    @include breakpoint(medium down) {
	    	padding-top: 55px;
	      background-color: $light-gray;
	      text-align: center;
	    }	  			
	  }

	  .search-radius-wrap {
	  	display: none;

	  	.item-text {
	  		display: inline;
	  		color: #737373;
	  	}
	  	select {
	  		display: inline;
	  		color: #737373;
    		width: 75px;
    		height: auto;
    		margin: 10px 0 0;    		
    		padding: 0 20px 0 0;
    		border: none !important;	
	  		font-size: inherit;
	  		line-height: inherit;    
	  		background-color: transparent;
	  		background-position: right -1rem center;

	    	@include breakpoint(medium down) {
	    	  //background-color: $light-gray;
	    	}		  			  		
	  	}	

	    @include breakpoint(medium down) {
	      background-color: $light-gray;
	      text-align: center;
	    }	  	  	
	  }
	}
	
	.search-results {
	  max-height: 300px;
	  overflow: auto;

	    @include breakpoint(medium down) {
	      overflow: visible;
	      display: block;
	      max-height: inherit;
	      background: $light-gray;
	    }

	    .result-item {
	      padding: 20px 13px 10px 21px;
	      border-left: 10px solid transparent;
	      border-top: 1px solid transparent;
	      border-bottom: 1px solid transparent;
	      cursor: pointer;
	      margin-bottom: 0;

	      .custom-check-grid {
	      	@include breakpoint(medium down) {
	      		padding-right: 63px;
	      	}	      	
	      }	      

	      .result-entry {
	      	@include breakpoint(medium down) {
	      		@include section-padding;
	      	}
	      }	 	        
	      .result-entry,
	      .result-entry p {
	      	font-size: 16px;
					line-height: 1.2em;
	      }
	      .result-entry p {
	      	margin-bottom: 20px;
	      }	 	        
				.accordion-title {
				  font-size: 16px;
				  font-weight: normal;
				  line-height: 1.2em;
				  border: none !important;
				  padding: 0;

				  :first-child {
				  	border-top: none !important;
					}		

					&:before {
					  top: 50%;
					  margin-top: -.9rem;
					  @include section-position-right;
					}

	        @include breakpoint(large) {
						&:before {
						  display: none;
						}					
				  }				  			  
				}	   
				.accordion-content {
					padding: 0;

					.accordion-content-top {
						margin-bottom: 30px;
					}

					&:last-child {
					  border-bottom: none !important;
					}						
				}            
	      .result-distance {
	        font-weight: normal;
	        display: inline-block;
	        margin-left: 10px;
	        display: none;
	      }
	      .result-content {
	      	margin-top: 20px;	
	      }

	      .visible-mobile {
	        display: none;
	        @include breakpoint(medium down) {
	            display: block;
	        }
	      }
	      .hidden-mobile {
	         @include breakpoint(medium down) {
	          display: none;
	        }
	      }	  
	      .foldable-content {
	         @include breakpoint(medium down) {
	          display: none;
	        }
	      }	    
	      &.active {
	      	@include breakpoint(large) {
	        	border-left: 10px solid $medium-gray;
	        	border-top: 1px solid $light-gray;
	        	border-bottom: 1px solid $light-gray;
	        	background-color: $light-gray;
	        }

	        @media screen and (max-width: 800px) {
	          .leaflet-container {
	              display: block;
	              padding: 5px 0;
	              height: 200px;
	          }
	        }
	      }
	      &:hover {
	      	@include breakpoint(large) {
	          border-left: 10px solid lighten($medium-gray, 20%);
	          border-top: 1px solid $light-gray;
	          border-bottom: 1px solid $light-gray;
	        }
	      }

	      @include breakpoint(medium down) {
	        border: none;
	        background-color: $white;
	        margin-bottom: 15px;
	        padding-left: 0;
	        padding-right: 0;
	      }	 
	      &.is-custom-image {
	      	padding: 10px 25px 10px 21px;
	      	@include breakpoint(medium down) {
	      		padding: 20px 0 10px 0;
	      	}	    
	      	.result-entry {
	        	padding-right: 0;	      		
	      	}  	
	      }	             
	    }

	    &.active {
	      background: $white;
	      
	      @include breakpoint(medium down) {
	        background: $light-gray;
	      }
	    }
	    .result-list {
	      //display: none;
	      padding: 0 0 20px;

	      &.active {
	        display: block;
	      }
	    }
	}

	.phone-button {
  	&.active {
  	  //pointer-events: none;
  		//&:hover, &:focus {
  		//	color: $button-color-hover;
  		//	background-color: transparent;
  		//	border: $button-hollow-border-width $button-fill $button-border-color-hover;
  		//}  	  
  	}	  		  	
	}	  

	.leaflet-container {
		background: $body-background;
		outline: 0;		
		font-family: $body-font-family;
	  font-size: $paragraph-font-size;
	  line-height: $paragraph-lineheight;

	  a.leaflet-popup-close-button {
			color: $medium-gray;
			font-size: 23px;
    	top: 8px;
    	right: 10px;
    	font-weight: normal;			
		}
	}
	.leaflet-pane.leaflet-popup-pane {
	  @include breakpoint(medium down) {
	    display: none !important;
	  }		
	}
	.leaflet-popup-content {
	  width: 368px !important;
	  margin: 25px 24px 29px;
	}
	
	.leaflet-popup-content-wrapper {
	  border-radius: 0;
	  min-height: 190px;
	  background: url($pergament);
	    
	  .result-popup-top {
	    margin-bottom: 20px;
	  }

	  .button {
	    width: 100%; 
	  } 

		.maps-button {
			margin-bottom: 0;
		} 	  
	}

	.leaflet-pane > svg path.leaflet-interactive {
	  stroke: $medium-gray;
	  stroke-opacity: 0;
	  fill: $medium-gray;	
	  fill-opacity: 0.5;

	  @include breakpoint(medium down) {
	    stroke-opacity: 0;
	    fill-opacity: 0;
	  }
	}	

	.search-marker-icon {
		opacity: 0;
	  @include breakpoint(medium down) {
	    opacity: 0;
	  }
	}	

  .result-custom-check .item-type {
    text-transform: uppercase;
    color: #6b6b6a;
    background-color: #d0cfcc;
    border-radius: 5px;
    padding: 0 5px;
    font-size: 11px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 15px;

    .icon {
  		font-size: 9px;
  		vertical-align: middle;            	
    }
    .item-text {
  		vertical-align: middle;            	
    }            
  } 	
	.leaflet-popup-content {
  	.result-custom-check .item-type {
  	  margin-bottom: 5px;          
  	}
	}  

  .result-custom-check.result-custom-image .item-type {
    display: inline-block;
    color: transparent;
    background-color: transparent;
    border-radius: none;
    padding: 0;
    margin-bottom: 0;
    min-width: 78px;

    .image-proshop {
  		max-width: 65px;
  		width: 65px;            	
    }            
  } 	
	.leaflet-popup-content {
		.result-custom-image {
			padding-right: 13px;
			min-width: 78px;
			margin-top: 3px;
		}
  	.result-custom-check.result-custom-image .item-type {
  	  margin-bottom: 0;          
  	}
	}
	.custom-check-text {
		padding-right: 10px;
	}
}

// Geocoder
// --------------
.geocoder {
  margin: 0 0 $form-spacing;

  .selected {
    background-color: $medium-gray;
    color: $white;
	}

	.geocodifyInput {
	  margin-bottom: 0;
	}

	.geocodifyDropdown li {
		font-family: $input-font-family; 
		font-size: $input-font-size;
		font-weight: $input-font-weight;
		line-height: $input-line-height;		
	}  	
}