.slider-wrapper {
  position: relative;
}

.slick-slider, 
.slick-slider .slick-list, 
.slick-slider .slick-track {
  height: 100%;
}

.slick-slide {
  outline: none;
}

.slick-list {
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  &.draggable {
    opacity: 1;
  }
}

.slick-arrow {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
  border: none;
  outline: none;
  background: transparent;
  font-size: 0;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:before {
    font-family: 'icon-font';
    font-size: $slick-arrow-font-size;
    line-height: 1;
    color: $slick-arrow-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 300ms ease-in-out;
  }
 
  &:active, 
  &:focus, 
  &:hover {
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }

}

.slick-arrow.slick-disabled {
  //display: none !important;
}

.slick-next {
  right: 5px;
  left: auto;

  &:before {
    content: '\e801';
    @include breakpoint(medium down) {
      content: '\e801';
    }      
  }

  &:hover {
    right: -5px;
  }
}

.slick-prev {
  left: 5px;
  right: auto;

  &:before {
    content: '\e800';
    @include breakpoint(medium down) {
      content: '\e800';
    }       
  }

  &:hover {
    left: -5px;
  }  
}

[dir="rtl"] {
  .slick-next {
    left: 5px;
    right: auto;
  
    &:before {
      content: '\e800';
      @include breakpoint(medium down) {
        content: '\e800';
      }       
    }
  
    &:hover {
      left: -5px;
    }  
  }

  .slick-prev {
    right: 5px;
    left: auto;
  
    &:before {
      content: '\e801';
      @include breakpoint(medium down) {
        content: '\e801';
      }      
    }
  
    &:hover {
      right: -5px;
    }
  }
}


.slider-control {
  display: none;
  text-align: center;
  position: relative;
  width: $slick-control-width;
  height: $slick-control-height;
  margin: $slick-control-margin-top auto 0;   
  font-size: $slick-font-size;
  font-weight: $slick-font-weight;
  line-height: $slick-control-height;
  color: $slick-font-color;
  background-color: $slick-control-background-color;
  border-radius: 30px;
  z-index: 20;  
  cursor: pointer;  

  @include breakpoint(small only) {
    width: $slick-control-width-small;
    margin: $slick-control-margin-top-small auto 0;
  } 

  .counter {
    @include breakpoint(small only) {
      font-size: $slick-font-size-small;
    }   
  }

  .slick-arrow {
    position: absolute;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0;
    height: $slick-control-height;
    width: auto;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:before {
      font-family: 'icon-font';
      font-size: $slick-arrow-font-size;
      font-weight: $slick-font-weight;
      line-height: 1;
      color: $slick-arrow-color;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: all 300ms ease-in-out;

      @include breakpoint(small only) {
        color: $slick-arrow-color-small;
      }       
    }

  }

  .slick-arrow.slick-disabled {
    opacity: 0.4;
  }
  .slick-next {
    right: 20px;
  }
  .slick-next:before {
    content: '\e801';
    @include breakpoint(small only) {
      content: '\e801';
    }      
  }

  .slick-prev {
    left: 20px;
  }
  .slick-prev:before {
    content: '\e800';
    @include breakpoint(small only) {
      content: '\e800';
    }       
  }
}


/* Dots */
.slick-dotted {

  .slick-dots {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: right;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: rgba($black, 0.3);
    border-radius: 50%;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    @include breakpoint(small only) {
        width: 8px;
        height: 8px;
    }

    &:hover,
    &:focus,
    &.slick-active {
        background-color: rgba($black, 0.7);
    }      
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
    &:hover:before,
    &:focus:before {
        opacity: 1;
    }   

    &:before {
      font-family: 'slick';
      font-size: 14px;
      line-height: 1.2rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 1.2rem;
      height: 1.2rem;
      //content: '•';
      text-align: center;
      opacity: .25;
      color: black;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    } 
  }

  .slick-dots li.slick-active button:before {
      opacity: .75;
      color: black;
  }
}

.slick-vertical {

  .slick-dots {
    width: 18px;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    bottom: inherit;    
  }
  .slick-dots li {
    display: block;
    margin: 6px;
    line-height: 8px;
  }  
}
