// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

.wp-caption-text {
  font-size: $wp-caption-font-size;
  line-height: $wp-caption-line-height;
  font-weight: $wp-caption-font-weight;
  font-style: $wp-caption-font-style;
  text-align: $wp-caption-text-align;
  color: $wp-caption-color;
  background: $wp-caption-background-color;
  padding: $wp-caption-padding; 

  display: flex;
  flex-direction: column;

  @if $wp-caption-text-align == left {
    align-items: flex-start;
  }   
  @if $wp-caption-text-align == right {
    align-items: flex-end;
  }  

  .image-title,
  .image-caption,
  .image-description {
    display: inline-block;    
  }  

  .image-caption + .image-description {
    margin-top: 4px;
  } 

  position: absolute;
  bottom: 0;
  right: 0;  
}