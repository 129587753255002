.post-head-section {
	text-align: center;
	border-bottom: 1px solid $global-border-color;

  .default-wrap {
    max-width: 1100px;
  }
  
	.post-head-section-image-wrap {
		margin-bottom: 55px;

		.image-wrap {
			&.aspect-ratio {
				@include breakpoint(medium) {
					@include aspect-ratio(1200, 400);
				} 
				@include breakpoint(small only) {
				  @include aspect-ratio(375, 200);
				}	
			}				
		}	
	}

	.section-headline-wrap {

		.section-pre-headline {
    	font-size: 14px;
    	line-height: 22px;
    	color: #727272;
    	letter-spacing: 0.125em;
    	text-transform: uppercase;
      max-width: $default-wrap-max-width;
      margin-left: auto;
      margin-right: auto;		
		}

    .category-link {
      text-decoration: none;
    }
    
    .round-image {
      margin: 0px auto 15px;
      width: 60px;
      height: 60px;

			@include breakpoint(small only) {
				margin: 0px auto 10px;
      	width: 40px;
      	height: 40px;
			} 	    		  
    }

    .section-post-headline {
      padding-top: 40px;

      @include breakpoint(small only) {
        padding-top: 30px;
      }       
    }

    .section-post-date {
      font-size: 20px;
      color: #888;
      font-weight: 500;
      margin-bottom: 0;
      max-width: $default-wrap-max-width;
      margin-left: auto;
      margin-right: auto;   
    }

		.section-sub-headline {
      font-size: rem-calc(20);
			color: #666;
    	margin-bottom: 10px;
      max-width: $default-wrap-max-width;
      margin-left: auto;
      margin-right: auto;
      
			@include breakpoint(small only) {
    		margin-bottom: 45px;
			}    	
		}

    .section-author-headline {
      font-size: 20px;
      color: #888;
      font-weight: 500;
      margin-bottom: 40px;
      max-width: $default-wrap-max-width;
      margin-left: auto;
      margin-right: auto;
      
      @include breakpoint(small only) {
        margin-bottom: 45px;
      }     
    }    
	}
}