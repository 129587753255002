html, body {
  position: relative;
  -webkit-overflow-scrolling: touch;
}

body {
  background-color: $white;
  color: $body-font-color;

  .window-loaded & {
    background-color: $body-background;
  }
}

.off-canvas-wrapper {
  //opacity: 0;
  opacity: 1;
  //transition: opacity 300ms ease-in-out;
}
.off-canvas {
  //opacity: 0;
  opacity: 1;
}
.sub-level-menu {
  display: none;
}
.init-loader  {
  //opacity: 1;
  opacity: 0;
}

.window-loaded {
  .off-canvas-wrapper {
    opacity: 1;
  }
  .off-canvas {
    opacity: 1;
  }   
  .init-loader {
    opacity: 0;
  }
}
.careta::after {
    @include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, down);
}

.main-content {
  overflow-x: hidden;
  transition: all 300ms ease-in-out;
}

.main {
	position: relative;
  overflow-x: visible;
  margin: 0 auto;
  background-color: $white;
  max-width: $main-max-width;

  @include breakpoint(large down) {
    @include main-margin-top;
  }  
  
}

.offline-wrapper {
  border: 3px solid red;
  > span {
    color:red;
    padding-left: 10px;
  }
}

.visibility-wrapper {
  border: 3px solid $alert-color;
  position:relative;
  .info-text {
    position:absolute;
    top:-2px;
    left:0;
    display:inline-block;
    background-color: $alert-color;
    color:#fff;
    padding:0 2px;
    font-size:10px;
    font-weight:bold;
    z-index:1000;
  }
  .badge {
    font-size: .3rem;
    vertical-align: text-top;
  }

  &.warning {
    border: 3px solid $warning-color;
    .info-text {
      color:#fff;
    }    
  }     
}

section {
  position: relative;
  padding-top: $section-vertical-padding;
  padding-bottom: $section-vertical-padding;
  width: 100%;
  z-index: 1;

  @include breakpoint(medium down) {
    padding-top: $section-vertical-padding-small;
    padding-bottom: $section-vertical-padding-small;
  }  


  &.max-width-section {
    max-width: $global-width;
    margin: 0 auto;
  }
  &.top-section {
    padding-top: 0;  
  }
  
  &.bottom-section {
    padding-bottom: 0;  
  }
}

.section-inner {
  position: relative;
  max-width: $section-max-width;
  margin-left: auto;
  margin-right: auto; 
  @include section-padding;
  z-index: 3;

  &.no-horizontal-padding-small {
    @include breakpoint(small only) {
      padding-left: 0;
      padding-right: 0;
    }     
  }  
}

.default-wrap {
  max-width: $default-wrap-max-width;
  margin-left: auto;
  margin-right: auto;
  position: relative;   
}

.wide-wrap {
  max-width: $wide-wrap-max-width;
  margin-left: auto;
  margin-right: auto;
  position: relative;   
}

.narrow-wrap {
  max-width: $narrow-wrap-max-width;
  margin-left: auto;
  margin-right: auto;
  position: relative;   
}

.section-headline-wrap.text-center {
  max-width: $headline-wrap-max-width;
  margin-left: auto;
  margin-right: auto;   
}

.section-content-wrap {
  position: relative;
}

.divider-line {
  width: 100%;
  height: $divider-line-height;
  background-color: $divider-line-color;  
}

.divider-block {
	position: relative;
  width: 100%;
  max-width: $section-max-width;
  margin-left: auto;
  margin-right: auto;   
	height: $divider-block-height;
	background-color: $divider-block-color;
}

.divider-block + .divider-block,
.main .divider-block:last-child {
  display: none;
}

.scrolldown-button {
  position: absolute;
  bottom: 15px;
  margin: 0 auto;
  //box-shadow: $card-shadow;  
  font-size: 33px;
  line-height: 37px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 5;

  @include breakpoint(medium down) {
    display: none;
  }  
}

.h-scroll-mobile {
  @include breakpoint(medium down) {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; 
  }
}
.h-scroll-mobile::-webkit-scrollbar {
  @include breakpoint(medium down) {
    display: none; 
  }
}

.breadcrumbs-wrap {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ececec;
  @include section-padding;

  .breadcrumbs {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    @include section-position-left;   

    li:not(:last-child):after {
      font-family: 'icon-font';
      font-size: 12px;

      @include breakpoint(small only) {
        display: none;
      }       
    }

    li {
      margin: 0;

      &.current {
        font-weight: bold;

        @include breakpoint(small only) {
          display: none;
        }        
      }
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .icon-arrow-left {
    font-size: 12px;

    @include breakpoint(medium) {
      display: none;
    } 
  }

  .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    @include section-position-right;
    font-size: rem-calc(14px);
    padding: rem-calc(9px) rem-calc(26px); 

    @include breakpoint(small only) {
      max-width: 165px;
      min-width: auto;
      font-size: rem-calc(12px);
    }        
  }

  &.product,
  &.bg-black,
  &.bg-style-image,
  &.bg-style-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-bottom: 1px solid rgba(255,255,255,0.3);

    .breadcrumbs {
      a,
      li,
      li:after {
        color: $white;
      }

    }
  }

  &.product.sticky {
    position: fixed;
    background-color: rgba(0,0,0,0.3);

    @include breakpoint(large down) {
      top: $header-height-small;
    }
  }

  &.bg-style-image {
    background-color: transparent;
  }
}

.widget {
  padding: 30px 45px;

  @include breakpoint(large down) {
    @include section-padding; 
  }
  .widget-headline-wrap {
    margin-bottom: 50px;

    @include breakpoint(large down) {
      margin-bottom: 30px; 
    }  
    @include breakpoint(small only) {
      margin-bottom: 20px;
    }  

    .widget-headline {
      text-transform: uppercase;
    }     
  }
}

.reveal {

  .top-wrap {
  	position: relative;
    margin-bottom: 40px;  
  }

  .bottom-wrap {
		position: relative;
  }   

  @include breakpoint(medium) {
    outline: none;
    width: 82%;
    max-width: 980px;
    max-height: 680px;
  }

  @include breakpoint(small only) {
    //padding: $reveal-padding-small; 
  }  

  &.lightbox {
    @include breakpoint(medium) {
      max-height: inherit;
      width: 70%;
      max-width: 70%;
    }
  } 
}
.reveal-overlay {
  overflow-y: hidden;
  z-index: 99996; 
}
html.lightbox-open.is-reveal-open.zf-has-scroll {
  body {
    overflow-y: hidden;
  }
  .reveal-overlay {
    overflow-y: scroll;
  }
}

.custom-popup {
	@media only screen and (max-height: 800px) {
  	max-height: calc(80vh - #{$header-height});
  	top: calc(#{$header-height} + 20px) !important;
	
  	.solid-header & {
  	  top: calc(#{$header-height-scrolled} + 20px) !important;
  	  max-height: calc(80vh - #{$header-height-scrolled});
  	} 
	
  	@include breakpoint(large down) {
  	  top: $header-height-small !important;
  	  max-height: calc(80vh - #{$header-height-small});
	
  	  .solid-header & {
  	    top: $header-height-small !important;
  	    max-height: calc(80vh - #{$header-height-small});
  	  }    
  	}
	}

  @include breakpoint(large down) {
    top: 0 !important;
    min-height: 100vh;
    padding: 0 0 60px 0;
    overflow-y: scroll;
    background-color: $light-gray;

    .top-wrap {
      height: $header-height-small;
      background-color: $toggle-menu-background-color;
      margin-bottom: 0;  

      .close-button {
        color: $white;
        @include section-position-left;
        //margin-left: -8px;
        top: 50%;
        transform: translateY(-50%); 
        &:hover, &:focus {
          outline: none !important;      
        }           
      }    
    }   

    .section-headline {
      @include absolute-center;
      color: $white;
      font-size: 20px;
      font-family: $body-font-family;
      font-weight: bold;
      text-transform: initial;
      margin: 0;       
    }

    .bottom-wrap {
      background-color: $light-gray;
    }     
  }  
}

div:not(.aspect-ratio) > .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe, object, embed { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}   

.no-scroll {
	iframe, object, embed { 
	  pointer-events: none;
	}  	
} 

input,
textarea {
  // Placeholder text
  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }  
  &:-ms-input-placeholder {
    color: $input-placeholder-color !important;
    opacity: 1 !important;    
  }

  &::-ms-clear {
    display: none;
  }  
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $input-color;
}

figure {
  max-width: 100%;
}

.image-wrap {
  position: relative;
}

img {
  width: 100%;
}

.bg-image {
  position: absolute;
  top: 0;
  left:0;
  background-size: cover;
  background-position: 50%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.round-image {
  background-size: cover;
  background-position: center center;  
  background-color: $medium-gray;
  width: 140px;
  height: 140px;
  border-radius: 50%;

  @include breakpoint(small only) {
    width: 110px;
    height: 110px;
  }             
}

.image-gradient {
  position: absolute;
  top: 0;
  left:0;
  bottom: 0;
  background-size: cover;
  background: linear-gradient(360deg, rgba(0,0,0,.7),transparent 50%);
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 1;
}

.image-overlay {
  position: absolute;
  top: 0;
  left:0;
  bottom: 0;
  background-size: cover;
  background-color: rgba(0,0,0,.3);
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 1;
}

.new-cookie-notice {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;    
  background-color: rgba(80,80,80,0.9);  
  z-index: 99999999999999;

	.default-wrap {
		max-width: 960px;
	}

  .cookie-notice-inner { 
    padding: 15px 0;
    margin: 0 auto;
  
    @include breakpoint(small only) {
      display: block;
    }     
  }
  .cookie-notice-text {
    display: inline-block;
    padding-right: 20px;

    a {
    	color: $white;
    }

    @include breakpoint(small only) {
      padding-right: 30px;  
    }
  }
  .cookie-notice-text, 
  #close-cookie-notice {
    @include custom-font-styles(14px, 19px, 400, $white, true, 0, 0); 

    @include breakpoint(small only) {
      width: 100%;
      display: block;  
    }        
  }
  #close-cookie-notice {
    .close-button-text {
      margin: 0;
    	@include breakpoint(small only) {
    	  width: 100%;
    	  margin: 15px auto 0;
    	}      
    }

    .close-button {
      top: 20px;
      right: 0;
      color: $white;
    }
  }    
}

.CookieDeclaration {
  display: none;
}

.post-item-link {
  text-decoration: none;
  outline: none;
  width: 100%;
  height: 100%;
  display: block;
  transition: background-color .3s linear;

  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    background-color: $global-border-color;
  }  
}

.card {
  position: relative;
  height: 100%;
	padding: 20px 45px 0px;
	margin-bottom: 0;

  @include breakpoint(large) {
  	border-top: none;
		border-left: none;
		border-bottom: none;		
  }		  

  .card-image-wrap {
    //overflow: hidden;
    position: relative;
    background-color: transparent;
    height: 90px;
    margin: 0 auto;

		@include breakpoint(medium down) {
			height: 60px;
		} 

		&.aspect-ratio {
			@include breakpoint(medium) {
				@include aspect-ratio(3000, 527);
			} 
			@include breakpoint(small only) {
			  @include aspect-ratio(375, 280);
			}	
		}    

    img {
    	position: relative;
      transition: all .3s linear;
      text-indent: -10000px;
      max-height: 100%;
      width: inherit;
      //top: 50%;
      //transform: translateY(-50%);        
      margin: 0 auto;
    }     
  }

  &:hover .card-image-wrap img {
    transform: scale(1.05,1.05);
  }

  .card-section {
    background: $card-background;

    @include breakpoint(small only) {
      padding: $card-padding-small;
    }  

    .card-title {
      @include custom-font-styles($card-title-font-size, $card-title-line-height, $card-title-font-weight, $card-title-font-color, false, 0, 15px);
      text-transform: $card-title-text-transform;

      @include breakpoint(small only) {
        @include custom-font-styles($card-title-font-size-small, $card-title-line-height-small, $card-title-font-weight-small, $card-title-font-color-small, false, 0, 15px);
        text-transform: $card-title-text-transform-small;
      }         
    }   
  }
}

.post-item:last-child {
	.card {
		@include breakpoint(large) {
			border-right: none;    
		}								
	}			
}

.item-grid-wrap {

  @include breakpoint(large down) {
    position: relative;   
    width: 100%;
    min-height: 100vh;
    padding: 0;
    padding-bottom: 60px;
    overflow-y: scroll;  
  }

  .top-wrap {
    @include breakpoint(large down) {
      position: fixed;
      top: 0;
      left: 0;
      height: $header-height-small;
      width: 100%;
      background-color: $white;
      margin-bottom: 0;  
      z-index: 9;
    }  

    .close-button {
      display: none;

      @include breakpoint(large down) {
        display: block;
        @include section-position-left;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
      }       
    }
  }

  .bottom-wrap {
    padding-bottom: 35px;

    @include breakpoint(large down) {
      margin-top: $header-height-small;
      background-color: $white;  
    }
  }  

  .grid-wrap {
    max-width: $section-max-width;
  }

  .card-wrap {
    text-decoration: none;

    @include breakpoint(large down) {
      position: relative;
      max-width: 100%;
      width: 100%;
    } 

    .card-title-wrap {
      width: 100%;
      position: relative;
    }
    .card-title {
      display: block;
      position: relative;
      width: 100%;
      padding-right: 25px;
      font-weight: bold;
    }
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      font-size: 11px;
      margin-right: -4px;
    }    
    .card-line {
      width: 100%;
      height: 1px;
      margin: 25px 0 20px;
      background-color: #c7c7c7;
    }
            
  }  
}

.group-popup {
  @include breakpoint(large down) {
    background-color: $global-border-color;      
  } 

  .grid-x {
    justify-content: center;
  }

  .section-inner {
    @include breakpoint(large down) {
      padding-left: 0;
      padding-right: 0;      
    }     
  }

  .item-grid {
    @include breakpoint(large down) {
      display: block;
      margin: 0;      
    }     
  }

  .popup-headline {
    font-size: 37px;
    line-height: 44px;
    font-weight: 500;
    text-align: center;
    max-width: 405px;
    margin: 0 auto 55px;
  }

  .popup-image-wrap {
    margin: 0 auto 45px;
    height: 90px;
    text-align: center;

    img {
      max-height: 100%;
      height: 100%;
      width: inherit;   
      margin: 0 auto;   
    }
  }
  .card-wrap {
    max-width: 195px;
    margin-left: 10px;
    margin-right: 10px;

    @include breakpoint(large down) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      position:relative;
      text-align: left;
      text-transform: $toggle-menu-text-transform;
      color: $toggle-menu-anchor-color;
      font-size: $toggle-menu-font-size;
      line-height: $toggle-menu-line-height;
      font-weight: $toggle-menu-font-weight;
      outline: none !important;
      @include vertical-padding($toggle-menu-item-vertical-padding);
      @include section-padding;   
      padding-right: 35px;
      border-bottom: 1px solid $toggle-menu-item-border-color;

      &:last-child {
        border: none;            
      }                  
    } 

    .card-title-wrap {
      margin-top: 20px;

      @include breakpoint(large down) {
        margin-top: 0;
        display: inline-block;  
        position: relative;
        height: 100%;   
      }      
    }

    .card-title {
      color: $secondary-color;
      hyphens: auto;

      @include breakpoint(large down) {
        font-weight: normal;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;              
      }      
    }

    .icon {
      color: $secondary-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;      
    }

    .card-image-wrap-wrap {
      @include breakpoint(large down) {
        display: inline-block;
        width: 220px;     
      } 
    }

    .card-image-wrap {
      background-color: $light-gray;
      overflow: hidden;

      &.aspect-ratio {
        @include breakpoint(xlarge) {
          @include aspect-ratio(200, 115);
        }
        @include breakpoint(small only) {
          @include aspect-ratio(145, 82);
        }        
      }      

      img {
        position: relative;
        transition: all .3s linear;
        text-indent: -10000px;
        max-height: 100%;
        width: inherit;
        margin: 0 auto;
      }            
    }
    &:hover .card-image-wrap img {
      transform: scale(1.05,1.05);
    }  
  }
}

.ie-ver-11 .group-popup {
  .item-grid {
    display: block;
    text-align: center;

    .card-wrap {
      display: inline-block;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

.product-popup {

  .grid-x {
    justify-content: center;
  }

  .card-wrap {
    max-width: 185px;
    margin-left: 10px;
    margin-right: 10px;

    .card-image-wrap {
      position: relative;
      background-color: transparent;
      height: 65px; //90px;
      margin: 0 auto;
      text-align: center;

      img {
        position: relative;
        transition: all .3s linear;
        text-indent: -10000px;
        max-height: 65px; //100%;
        max-width: 150px;
        width: inherit;
        margin: 0 auto;
      }      
    }
    &:hover .card-image-wrap img {
      transform: scale(1.05,1.05);
    }            
  }
}

.ie-ver-11 .product-popup {
  .grid-x {
    display: block;
    text-align: center;

    .card-wrap {
      display: inline-block;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

.sharing-buttons {
  text-align: center;

  .share-button {
    border-radius: 50%;
    color: #807870;
    background-color: #f5f4f2;
    display: inline-block;
    min-height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 5px;
    transition: all 0.3s ease;  

    &:hover {
      color: #f5f4f2;
      background-color: #807870;
    }

    @include breakpoint(small only) {
      margin: 5px;
    } 
  }  

  .whatsapp-share-button {
    display: none;

    .icon:before {
        font-size: 20px;
        margin: 0;
    }    
  }   

  .mail-share-button {
    .icon {
      &:before {
        margin-left: 0;
      }
    }
  }      
}
